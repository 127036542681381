// MY SONGS
const MY_SONGS = (state = [], { type, payload }) => {
  switch (type) {
    case "MY_SONGS":
      return payload;

    case "ADD_SONG":
      return [...state, payload];

    case "ADD_SONG_COVER":
      const id = payload.id;
      const cover = payload.cover;
      const songToUpdate = state.filter((item) => {
        if (item._id === id) {
          item.cover = cover;
        }
        return item;
      });
      return songToUpdate;

    case "DELETE_SONG":
      const undeleted = state.filter((item) => item._id !== payload.id);
      return undeleted;

    default:
      return state;
  }
};

// SONGS
const SONGS = (state = [], { type, payload }) => {
  switch (type) {
    case "SONGS":
      return payload;
    case "LIKE_SONG":
      const songId = payload.id;
      const userId = payload.userId;
      const updatedSongs = state.filter((song) => {
        if (song._id === songId) {
          song.likes.push(userId);
        }

        return song;
      });
      return updatedSongs;

    case "UNLIKE_SONG":
      const songID = payload.id;
      const userID = payload.userId;
      const modifiedSongs = state.filter((song) => {
        if (song._id === songID) {
          song.likes = song.likes.filter((item) => item !== userID);
        }

        return song;
      });
      return modifiedSongs;

    case "COMMENT":
      const _id = payload.id;
      const comment = payload.comment;
      const songState = state.filter((song) => {
        if (song._id === _id) {
          song.comments.push(comment);
        }

        return song;
      });
      return songState;

    default:
      return state;
  }
};
// TOP SONGS
const TOP_SONGS = (state = [], { type, payload }) => {
  switch (type) {
    case "TOP_SONGS":
      return payload;
    default:
      return state;
  }
};
// PLAYING SONG
const PLAYING_SONG = (state = {}, { type, payload }) => {
  switch (type) {
    case "PLAYING_SONG":
      return payload;
    default:
      return state;
  }
};

// SEARCHED SONGS
const SEARCHED_SONGS = (state = [], { type, payload }) => {
  switch (type) {
    case "SEARCHED_SONGS":
      return payload;
    default:
      return state;
  }
};

const Song = {
  SONGS,
  TOP_SONGS,
  PLAYING_SONG,
  MY_SONGS,
  SEARCHED_SONGS,
};
export default Song;
