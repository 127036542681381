import Hero from "./Hero";

const Welcome = () => {
  return (
    <div className="welcome">
      <Hero />
    </div>
  );
};

export default Welcome;
