import Pricing from "./Pricing";
import Songs from "./Songs";

const Content = ({ setDisplay }) => {
  return (
    <div className="content-container">
      <div className="main-container">
        <Songs setDisplay={setDisplay} />
        <Pricing />
      </div>
    </div>
  );
};

export default Content;
