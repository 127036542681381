// SONGS
const SONGS = (songs) => {
  return {
    type: "SONGS",
    payload: songs,
  };
};

// MY SONGS
const MY_SONGS = (songs) => {
  return {
    type: "MY_SONGS",
    payload: songs,
  };
};

// TOP SONGS
const TOP_SONGS = (songs) => {
  return {
    type: "TOP_SONGS",
    payload: songs,
  };
};

// PLAYING SONGS
const PLAYING_SONG = (song) => {
  return {
    type: "PLAYING_SONG",
    payload: song,
  };
};

// DELETE SONG
const DELETE_SONG = (song) => {
  return {
    type: "DELETE_SONG",
    payload: song,
  };
};

// ADD SONG
const ADD_SONG = (song) => {
  return {
    type: "ADD_SONG",
    payload: song,
  };
};

// ADD SONG COVER
const ADD_SONG_COVER = (song) => {
  return {
    type: "ADD_SONG_COVER",
    payload: song,
  };
};

// LIKE A SONG
const LIKE_SONG = (id) => {
  return {
    type: "LIKE_SONG",
    payload: id,
  };
};

// UNLIKE A SONG
const UNLIKE_SONG = (id) => {
  return {
    type: "UNLIKE_SONG",
    payload: id,
  };
};

// COMMENT ON A SONG
const COMMENT = (data) => {
  return {
    type: "COMMENT",
    payload: data,
  };
};

// SEARCHED SONGS
const SEARCHED_SONGS = (songs) => {
  return {
    type: "SEARCHED_SONGS",
    payload: songs,
  };
};

const SongActions = {
  SONGS,
  TOP_SONGS,
  PLAYING_SONG,
  DELETE_SONG,
  ADD_SONG,
  ADD_SONG_COVER,
  LIKE_SONG,
  UNLIKE_SONG,
  COMMENT,
  MY_SONGS,
  SEARCHED_SONGS,
};

export default SongActions;
