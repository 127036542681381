// import ProdCard from "../../assets/ProdCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SongCardLoader from "../../assets/SongCardLoader";
import SongCard from "../../assets/SongCard";

const Songs = ({ setDisplay }) => {
  // Redux state
  const home_data = useSelector((s) => s.home_data);

  // Render top beats
  const renderTopBeats = () => {
    if ("topBeats" in home_data) {
      if (home_data.topBeats.length >= 1) {
        return (
          <>
            <div className="sub-heading-cont">
              <h2>Beats</h2>
              <p>Listen to my top beats</p>
            </div>
            {home_data.topBeats.map((beat, ind) => {
              return (
                <SongCard
                  key={ind}
                  _id={beat._id}
                  name={beat.name}
                  artist={beat.artist}
                  img={beat.cover.Location}
                  plays={beat.plays}
                  downloads={beat.downloads}
                  audioFile={beat.audio.Location}
                  type={beat.type}
                  setDisplay={setDisplay}
                />
              );
            })}
            {home_data.topBeats.length === 8 && (
              <div className="more">
                <Link to="/beats">More beats</Link>
              </div>
            )}
          </>
        );
      } else {
        const arr = [1, 2, 3, 4, 5, 6, 7, 8];
        return arr.map((i, ind) => <SongCardLoader key={ind} />);
      }
    }
  };

  // Render top tracks
  const renderTopTracks = () => {
    if ("topTracks" in home_data) {
      if (home_data.topTracks.length >= 1) {
        return (
          <>
            <div className="sub-heading-cont">
              <h2>Tracks</h2>
              <p>Listen to my top tracks</p>
            </div>
            {home_data.topTracks.map((track, ind) => {
              return (
                <SongCard
                  key={ind}
                  _id={track._id}
                  name={track.name}
                  artist={track.artist}
                  img={track.cover.Location}
                  plays={track.plays}
                  downloads={track.downloads}
                  audioFile={track.audio.Location}
                  type={track.type}
                  setDisplay={setDisplay}
                />
              );
            })}
            {home_data.topTracks.length === 8 && (
              <div className="more">
                <Link to="/tracks">More tracks</Link>
              </div>
            )}
          </>
        );
      }
    }
  };

  return (
    <div className="songs-section">
      <div className="heading">
        <h1>Listen to music</h1>
        <span></span>
      </div>

      {/* Song Cards */}
      {renderTopBeats()}

      {/* Song Cards */}
      {renderTopTracks()}
    </div>
  );
};

export default Songs;
