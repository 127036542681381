import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import Menu from "./Menu";
import { useState } from "react";
import axios from "axios";
import Functions from "../abstracts/Functions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SongActions from "../redux/actions/SongActions";

const Navs = () => {
  // Use state
  const [search, setSearch] = useState(false);
  const [menu, setMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [q, setQ] = useState("");

  // History
  const history = useHistory();

  // Dispatch
  const dispatch = useDispatch();

  const toggleSearch = (e) => {
    e.preventDefault();
    setSearch(!search);
  };

  const toggleDesktopSearch = (e) => {
    e.preventDefault();
    setShowSearch(!showSearch);
  };

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenu(!menu);
  };

  // When a user types in the search box,
  // set the query value
  const onChange = (e) => {
    setQ(e.target.value);
  };

  // Search a song by name or artist
  const searchSongs = (e) => {
    e.preventDefault();

    if (q) {
      axios({
        url: `${Functions.getAPI()}/songs/search?q=${q}`,
        method: "GET",
        data: { q },
      })
        .then(async (res) => {
          const data = await res.data;
          // If status is 200, let the user login
          if (res.status === 200) {
            // Dispatching
            dispatch(SongActions.SEARCHED_SONGS(data.songs));

            // Redirecting the user
            history.push(`/songs/search?q=${q}`);
          }
        })
        .catch((e) => {
          const res = e.response;
          if (res) console.log(res.data);
          else console.log(e);
        });
    }
  };
  return (
    <nav className="landing-nav">
      <DesktopNav
        // togglePopupOpts={togglePopupOpts}
        toggleDesktopSearch={toggleDesktopSearch}
        setShowSearch={setShowSearch}
        showSearch={showSearch}
        onChange={onChange}
        searchSongs={searchSongs}
        q={q}
      />
      {/* <DesktopSearch
        showSearch={showSearch}
        toggleDesktopSearch={toggleDesktopSearch}
      /> */}
      <MobileNav
        toggleSearch={toggleSearch}
        toggleMenu={toggleMenu}
        menu={menu}
        search={search}
      />
      <Menu menu={menu} toggleMenu={toggleMenu} />

      {/* Popup options */}
      {/* <PopupOpts
        togglePopupOpts={togglePopupOpts}
        showOpts={showOpts}
        setShowOpts={setShowOpts}
      /> */}
    </nav>
  );
};

export default Navs;
