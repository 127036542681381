import Navs from "./assets/Navs";
// import BottomNav from "./assets/BottomNav";
import Footer from "./assets/Footer";
// import Subscribe from "./assets/Subscribe";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import AuthActions from "./redux/actions/AuthActions";
import USER from "./redux/actions/User";
import Functions from "./abstracts/Functions";
import { useRef } from "react";
import BuyerActions from "./redux/actions/BuyerActions";
import Error404 from "./pages/404";

// // Routes
import Home from "./routes/home";
import Auth from "./routes/auth";
import Admin from "./routes/admin";
import Song from "./routes/song";
import Shop from "./routes/shop";
// import Job from "./components/routes/job";
// import Shop from "./components/routes/shop";
import Static from "./routes/static";

// Audio stuff
import MiniPlayer from "./assets/MiniPlayer";
import AudioSetup from "./abstracts/AudioSetup";

function App() {
  // Use state for audio
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [playStr, setPlayStr] = useState("play");
  const [audioPlaylist, setAudioPlaylist] = useState([]);
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();

  // Creating the audio element
  const audio = useRef();

  const audioSetup = new AudioSetup(audioPlaylist[count], audioPlaylist, count);

  useEffect(() => {
    localStorage.clear();

    axios({
      url: `${Functions.getAPI()}/login`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching

        // Logging in the user
        dispatch(AuthActions.login());
        localStorage.setItem("auth", true);

        // Adding the user information
        dispatch(USER.USER_INFO(data.user));

        // Getting user's shopping cart info
        dispatch(BuyerActions.ADD_TO_CART(data.user.cart));
      })
      .catch((e) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log(e);
        }

        // Logging out the user
        dispatch(AuthActions.logout());
        localStorage.setItem("auth", false);

        // Removing the user information
        dispatch(USER.DELETE_ACCOUNT());
      });
  }, [dispatch]);

  return (
    <div className="container">
      {/* Routes */}
      <Router>
        {/* Main navigation */}
        <Navs />

        {/* Subscribe banner */}
        {/* <Subscribe /> */}

        <Switch>
          {/* Home */}
          {Home.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Song */}
          {Song.map((route, ind) => {
            if (route.path === "/playing/:_id") {
              return (
                <route.routeType
                  path={route.path}
                  key={ind}
                  exact={route.exact}
                  render={(props) => (
                    <route.component
                      {...props}
                      audio={audio}
                      audioPlaylist={audioPlaylist}
                      count={count}
                      setCount={setCount}
                      setAudioPlaylist={setAudioPlaylist}
                      audioPlayed={audioPlayed}
                      setAudioPlayed={setAudioPlayed}
                      playStr={playStr}
                      setPlayStr={setPlayStr}
                      audioSetup={audioSetup}
                    />
                  )}
                />
              );
            } else {
              return (
                <route.routeType
                  path={route.path}
                  key={ind}
                  exact={route.exact}
                  component={route.component}
                />
              );
            }
          })}

          {/* Shop */}
          {Shop.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Static */}
          {Static.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Auth */}
          {Auth.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Admin */}
          {Admin.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Not found */}
          <Route path="/*" component={Error404} />
        </Switch>

        {/* Bottom content (footer and navigation) */}
        {/* 
        <BottomNav /> */}

        {/* Minimized audio player */}
        <MiniPlayer
          audioPlayed={audioPlayed}
          setAudioPlayed={setAudioPlayed}
          setPlayStr={setPlayStr}
          audioSetup={audioSetup}
        />

        <Footer />
      </Router>
    </div>
  );
}

export default App;
