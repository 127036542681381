import SongCard from "../assets/SongCard";
import AdminNav from "../assets/AdminNav";
import { useEffect, useState } from "react";
import Functions from "../abstracts/Functions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../assets/Popup";
import FLASH from "../redux/actions/Flash";

const AdminSongs = () => {
  // Use state
  const [songs, setSongs] = useState([]);
  const [display, setDisplay] = useState([]);

  // Use selector
  const flash = useSelector((s) => s.flash);

  // Dispatch
  const dispatch = useDispatch();

  // Use effect
  useEffect(() => {
    axios({
      url: `${Functions.getAPI()}/song`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        const data = await res.data;

        setSongs(data.songs);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  }, []);

  // Delete a song
  const deleteSong = (e, id) => {
    e.preventDefault();

    // Show popup message
    setDisplay(true);

    axios({
      url: `${Functions.getAPI()}/song/delete/${id}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        const data = await res.data;

        setSongs(data.songs);
        dispatch(FLASH({ err: "", warn: "", success: data.success }));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, warn: "", success: "" }));
          } else {
            dispatch(FLASH({ err: "", warn: res.data.warn, success: "" }));
          }
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  // Render song cards
  const renderSongs = () => {
    if (songs.length > 0) {
      return songs.map((song, ind) => (
        <div
          key={ind}
          className="admin-song"
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <SongCard
            _id={song._id}
            name={song.name}
            artist={song.artist}
            img={song.cover.Location}
            plays={song.plays}
            downloads={song.downloads}
            audioFile={song.audio.Location}
            type={song.type}
            setDisplay={setDisplay}
            admin={true}
          />
          <button
            onClick={(e) => {
              deleteSong(e, song._id);
            }}
          >
            X
          </button>
        </div>
      ));
    } else {
      return <h2 className="not-found">No songs found!</h2>;
    }
  };

  return (
    <div className="main-cont">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}

      <AdminNav page="songs" />
      <div className="admin-cont">
        <div className="heading" style={{ marginBottom: "10px" }}>
          <h1>Admin Songs</h1>
        </div>

        <div className="song"></div>
        <div className="song">{renderSongs()}</div>
      </div>
    </div>
  );
};

export default AdminSongs;
