// Reusable functions
const funcs = {
  getTime: (thing) => {
    const time = Math.floor(thing);
    const min = Math.floor(time / 60);
    const sec = (time % 60).toFixed(0);
    return min + ":" + (sec < 10 ? "0" : "") + sec;
  },
};

// Playing and pausing audio
export const playToggler = (audio, { playing, setPlaying, str }) => {
  if (audio.src) {
    setPlaying(!playing);
    if (playing) {
      audio.pause();
      str("play");
    } else {
      audio.play();
      str("pause");
    }
  }
};

// Forward
export const forward = (
  audio,
  { setPlaying, audList, count, setCount, str }
) => {
  str("pause");
  setPlaying(true);

  if (!audio.paused) {
    console.log("audioPause");
    audio.pause();
    console.log("audio", audio);
  }

  if (audList && audio.src) {
    if (count >= audList.length - 1) {
      setCount(0);
      // audio.src = audList[0];
      audio.play();
    } else {
      setCount(count + 1);
      // audio.src = audList[count + 1];

      audio.play();
    }
  } else {
    audio.currentTime = 0;
  }
};

// Backward
export const backward = (
  audio,
  { setPlaying, audList, count, setCount, str }
) => {
  str("pause");
  setPlaying(true);
  if (audList) {
    if (count <= 0) {
      setCount(audList.length - 1);
      // audio.src = audList[audList.length - 1];
      audio.play();
    } else {
      setCount(count - 1);
      // audio.src = audList[count - 1];
      audio.play();
    }
  } else {
    audio.currentTime = 0;
  }
};

// Loop and unloop
export const loopToggler = (audio, { ref, onColor, offColor }) => {
  if (audio.src) {
    if (audio.loop) {
      audio.loop = false;
      ref.style.color = offColor;
    } else if (!audio.loop) {
      audio.loop = true;
      ref.style.color = onColor;
    }
  }
};

// Mute and unmute
export const mutetoggler = (audio, { setMuted, muted, str }) => {
  if (audio.src) {
    setMuted(!muted);
    audio.muted = muted;
    muted ? str("volume-down") : str("volume-up");
  }
};

// Volume change
export const volumeChanger = (audio, val) => {
  if (audio.src) {
    audio.volume = val / 100;
  }
};

// Seeking audio position
export const audioSeeker = (audio, val) => {
  if (audio.src) {
    const seekVal = audio.duration * (val / 100);
    audio.currentTime = seekVal;
  }
};

// Time update
export const updateTime = (audio, { currentTime, seekVal }) => {
  const currentSeekVal = audio.currentTime * (100 / audio.duration);

  // updating seek value when current time changes
  seekVal(currentSeekVal);

  // Showing current time and duration for the current audio
  currentTime(funcs.getTime(audio.currentTime));
};

export const times = (audio) => {
  return {
    currentTime: funcs.getTime(audio.currentTime),
    duration: funcs.getTime(audio.duration),
  };
};
