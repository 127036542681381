import axios from "axios";
import { useEffect, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../abstracts/Functions";
import CartItem from "../assets/CartItem";
import Popup from "../assets/Popup";
import BuyerActions from "../redux/actions/BuyerActions";
import FLASH, { RESTORE_FLASH } from "../redux/actions/Flash";
// import { useEmblaCarousel } from "embla-carousel";

const Cart = () => {
  // Getting state values
  const cart = useSelector((state) => state.cart);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Katwave | Shopping Cart!",
    desc: "View your shopping cart",
  });

  // Dispatch
  const dispatch = useDispatch();

  // State
  const [display, setDisplay] = useState(false);
  const [leaseTypeVal, setLeaseType] = useState("basic");
  const [pfHost, setPfHost] = useState("");
  const [pfData, setPfData] = useState({});

  // Create form elemet
  const form = createRef();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
    axios({
      url: `${Functions.getAPI()}/cart`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Setting data
        const myData = [];
        // Merchant details
        myData["merchant_id"] = data.pfdata.merchant_id;
        myData["merchant_key"] = data.pfdata.merchant_key;
        // URLS
        myData["return_url"] = data.pfdata.return_url;
        myData["cancel_url"] = data.pfdata.cancel_url;
        myData["notify_url"] = data.pfdata.notify_url;
        // Buyer details
        myData["name_first"] = data.pfdata.name_first;
        myData["name_last"] = data.pfdata.name_last;
        myData["email_address"] = data.pfdata.email_address;
        // Transaction details
        myData["m_payment_id"] = data.pfdata.m_payment_id;
        myData["amount"] = data.pfdata.amount;
        myData["item_name"] = data.pfdata.item_name;
        // Transaction options (Sending confirmation email to merchant)
        myData["email_confirmation"] = data.pfdata.email_confirmation;
        myData["confirmation_address"] = data.pfdata.confirmation_address;
        // Payment methods
        /*
        ‘eft’ – EFT
        ‘cc’ – Credit card
         ‘dc’ – Debit card
         ’mp’ – Masterpass
         ‘mc’ – Mobicred
         ‘sc’ – SCode
         ‘ss’ – SnapScan
         ‘zp’ – Zapper
         ‘mt’ – MoreTyme
         */
        myData["payment_method"] = data.pfdata.payment_method;

        // Generate signature
        myData["signature"] = data.pfdata.signature;

        // Dispatching
        dispatch(BuyerActions.ADD_TO_CART(data.cart));

        // Changing state for payfast data
        setPfData(myData);
        setPfHost(data.pfdata.pfHost);
      })
      .catch((e) => console.log(e));
  }, [dispatch]);

  // Cart Actions

  // Remove
  const removeItem = (e, _id) => {
    setDisplay(true);
    e.preventDefault();

    axios({
      url: `${Functions.getAPI()}/cart/remove/${_id}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        dispatch(BuyerActions.REMOVE_FROM_CART(_id));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  };

  // Update
  const updateCart = (e, _id) => {
    e.preventDefault();

    // Display popup
    setDisplay(true);

    // Remove flash messages
    dispatch(RESTORE_FLASH());

    axios({
      url: `${Functions.getAPI()}/cart/update/${_id}`,
      method: "POST",
      withCredentials: true,
      data: { leaseType: e.target.value },
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        dispatch(
          BuyerActions.UPDATE_CART({
            id: _id,
            leaseType: data.leaseType,
            price: data.price,
          })
        );

        // Set lease type value
        setLeaseType(data.leaseType);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  };

  // Place an order
  const order = (e) => {
    e.preventDefault();

    axios({
      url: `${Functions.getAPI()}/order`,
      method: "POST",
      withCredentials: true,
      data: { orderNumber: pfData.m_payment_id },
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        // const data = await res.data;

        // Submit the form
        form.current.submit();
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  };

  // Render functions

  // Payfast input fields
  const hiddednInputs = () => {
    const keys = Object.keys(pfData);
    const values = Object.values(pfData);

    return keys.map((i, ind) => {
      return (
        <input name={i} type="hidden" key={ind} value={values[ind].trim()} />
      );
    });
  };

  // Render cart items and cart summary
  const renderCart = () => {
    if (cart.items.length > 0) {
      // Cards
      return (
        <>
          <div className="card-container">
            {cart.items.map((i, ind) => (
              <CartItem
                key={ind}
                _id={i._id}
                name={i.name}
                artist={i.artist}
                image={i.image}
                price={i.price}
                updateCart={updateCart}
                currency={i.currency}
                removeItem={removeItem}
                leaseType={i.leaseType}
                leaseTypeVal={leaseTypeVal}
                setLeaseType={setLeaseType}
              />
            ))}
          </div>

          <form ref={form} action={pfHost} className="cart-summary">
            <h1>Cart summary</h1>
            <div className="total">
              <p>Total:</p>
              <h4>R{cart.total}</h4>
            </div>
            {hiddednInputs()}
            <br />
            <button onClick={order} type="submit" className="submit-button">
              Proceed to checkout
            </button>
          </form>
        </>
      );
    } else {
      return (
        <h2
          style={{
            marginTop: "12px",
            color: "#555",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Shopping cart empty!
        </h2>
      );
    }
  };

  return (
    <>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      <div className="main-cont">
        <div className="cart-cont">
          <div className="heading">
            <h1>Shopping cart</h1>
          </div>

          {renderCart()}
        </div>
      </div>
    </>
  );
};

export default Cart;
