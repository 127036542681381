import { createRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Messages from "../abstracts/Messages";
import FLASH from "../redux/actions/Flash";
import axios from "axios";
import Popup from "../assets/Popup";
import Functions from "../abstracts/Functions";

const ForgotPass = ({ history }) => {
  // Redux
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Katwave | Forgot Password",
    desc: " Forgot Password",
  });

  // Use effect
  useEffect(() => {
    dispatch(FLASH({ err: "", success: "", warn: "" }));
  }, [dispatch]);

  // Messages
  const [msg, setMsg] = useState({});

  // States
  const [emailVal, setEmailVal] = useState("");
  const [display, setDisplay] = useState(true);

  // Refs
  const email = createRef();

  // On change events
  const emailOnchange = (e) => {
    const emailRegx = /@/i;
    const { value, style } = e.target;
    setEmailVal(value);
    if (!emailRegx.test(value)) {
      setMsg({ email: "Must include '@'" });
      style.border = "3px solid red";
    } else {
      setMsg({ email: "" });
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const emailRegx = /\w@\w./i;
    if (email.current.value.trim() === "") {
      setMsg({ email: "Required field!" });
    } else if (!emailRegx.test(email.current.value)) {
      setMsg({ email: "Must include '@'" });
    } else {
      const email = emailVal;

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      axios({
        url: `${Functions.getAPI()}/account/verify`,
        method: "POST",
        data: {
          email,
        },
      })
        .then(async (res) => {
          setMsg({ email: "", pass: "" });

          // Waiting for the data before proceeding
          const data = await res.data;

          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((err) => {
          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          const res = err.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              console.log(res.data);
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    }
  };

  return (
    <div className="register-login login">
      <div className="form">
        <div className="heading">
          <h1>Verify email</h1>
        </div>

        {flash.err !== "" && <Messages err={flash.err} />}
        {flash.warn !== "" && <Messages warn={flash.warn} />}
        {flash.success !== "" && (
          <Popup
            display={display}
            setDisplay={setDisplay}
            success={flash.success}
            history={history}
          />
        )}
        <form className="form-slide" method="POST">
          <input
            type="text"
            className="formInput"
            name="email"
            id="email"
            placeholder="E-mail"
            value={emailVal}
            ref={email}
            onChange={emailOnchange}
          />
          <p style={{ color: "red" }} id="email-error">
            {msg.email}
          </p>
          <br />

          <button
            type="submit"
            className="submit-button"
            onClick={submitHandler}
          >
            Submit
          </button>
          <br />
          <Link to="/login">Login</Link>
        </form>
      </div>
    </div>
  );
};

export default ForgotPass;
