import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../../abstracts/Functions";
import FLASH from "../../redux/actions/Flash";

const Mini_Controls = ({
  volumeVal,
  changeVolume,
  muteStr,
  muteUnmute,
  setDisplay,
  loop,
  loopToggle,
}) => {
  // Selectors
  const playing_song = useSelector((s) => s.playing_song);

  // Redux
  const dispatch = useDispatch();

  const download = (e) => {
    e.preventDefault();

    // Dipslay popup message
    setDisplay(true);

    if (playing_song._id) {
      axios({
        url: `${Functions.getAPI()}/song/download/${playing_song._id}`,
        method: "POST",
        withCredentials: true,
      })
        .then(async (res) => {
          const data = await res.data;

          // Create an a element
          const a = document.createElement("a");
          // Set a's href to point to the song url
          a.href = data.url;
          a.download = data.song;
          // Append a to the body
          document.body.appendChild(a);
          // Dispatch click event on the a
          // Since a.click() does not work on the latest firefox
          a.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );

          // Remove a from body
          document.body.removeChild(a);

          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });
    }
  };
  return (
    <div className="mini-controls">
      <p className="loop" ref={loop} onClick={loopToggle}>
        <i className={`fas fa-repeat`}></i>
      </p>
      <p onClick={muteUnmute}>
        <i className={`fas fa-${muteStr}`}></i>
      </p>
      <input
        type="range"
        name="volume"
        id="volume"
        onInput={changeVolume}
        onClick={changeVolume}
        value={volumeVal}
      />
      <div
        className="download-cont"
        style={
          playing_song.type === "track"
            ? { display: "flex" }
            : { display: "none" }
        }
      >
        <button
          className="download"
          onClick={download}
          style={{ textDecoration: "none" }}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default Mini_Controls;
