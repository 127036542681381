import { Link } from "react-router-dom";

const AdminNav = ({ page }) => {
  return (
    <div className="admin-nav">
      <div className="nav-container">
        <Link to="/admin/songs" className={page === "songs" ? "active" : ""}>
          Songs
        </Link>
        <Link to="/admin/orders" className={page === "orders" ? "active" : ""}>
          Orders
        </Link>
        <Link to="/admin/upload" className={page === "upload" ? "active" : ""}>
          + Add
        </Link>
      </div>
    </div>
  );
};

export default AdminNav;
