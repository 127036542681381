// INBOXES
const INBOX = (state = [], { type, payload }) => {
  switch (type) {
    case "INBOX":
      return payload;
    case "NEW_INBOX":
      const inboxId = payload.id;
      const new_inbox = payload.inbox;
      const updatedInboxes = state.filter((inbox) => {
        if (inbox._id === inboxId) {
          inbox.inboxes.push(new_inbox);
        }

        return inbox;
      });
      return updatedInboxes;

    default:
      return state;
  }
};

const UNSEEN_INBOX =(state = {seen:true, num:0}, { type, payload }) => {
  switch (type) {
    case "UNSEEN_INBOX":
      return payload;
    default:
      return state;
  }
};

const Inbox = {
  INBOX,
  UNSEEN_INBOX,
};
export default Inbox;
