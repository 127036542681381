import { Link } from "react-router-dom";
import images from "../abstracts/Images";
import Functions from "../abstracts/Functions";

const AppCrash = () => {
  Functions.pageInfo({
    title: "Katwave | Error Something Went Wrong!",
    desc: "Error Something Went Wrong!",
  });

  return (
    <div className="error">
      <div className="error-container">
        <h2 style={{ color: "#fff" }}>OOPS!</h2>
        <br />
        <div className="img">
          <img src={images.illustrator} alt="illustration" />
        </div>
        <p>
          Something went wrong! <br />
          <span>Please check your internet connection</span>
        </p>
        <br />
        {/* If a user is not logged in */}
        <Link style={{ textAlign: "center" }} to="/">
          Go back home
        </Link>
      </div>
    </div>
  );
};

export default AppCrash;
