import images from "../../abstracts/Images";
import { useSelector } from "react-redux";

const Art_Controls = ({
  playStr,
  PlayAndPause,
  goBackward,
  goForward,
  playing,
  currentTime,
  duration,
}) => {
  // Use selectors
  const playing_song = useSelector((s) => s.playing_song);

  return (
    <div className="art-cont">
      <div className="art">
        <img
          src={
            playing_song._id && playing_song.cover.Location
              ? playing_song.cover.Location
              : images.hero
          }
          alt=""
        />
        <div className="main-controls">
          <p className="back" onClick={goBackward}>
            <i className="fas fa-backward"></i>
          </p>
          <p className="play" onClick={PlayAndPause}>
            <i className={`fas fa-${playing ? "pause" : "play"}`}></i>
          </p>
          <p className="forward" onClick={goForward}>
            <i className="fas fa-forward"></i>
          </p>
        </div>
        <div className="times">
          <p className="duration">{duration}</p>
          <span></span>
          <p>{currentTime}</p>
        </div>
      </div>
    </div>
  );
};

export default Art_Controls;
