import { Route } from "react-router";
import Player from "../pages/Player";
import Tracks from "../pages/Tracks";
import Beats from "../pages/Beats";
import SearchedSongs from "../pages/SearchedSongs";

const Song = [
  {
    routeType: Route,
    path: "/playing/:_id",
    exact: true,
    component: Player,
  },
  {
    routeType: Route,
    path: "/tracks",
    exact: true,
    component: Tracks,
  },
  {
    routeType: Route,
    path: "/beats",
    exact: true,
    component: Beats,
  },
  {
    routeType: Route,
    path: "/songs/search",
    exact: true,
    component: SearchedSongs,
  },
];

export default Song;
