const Loader = ({ loading }) => {
  const renderLoader = () => {
    return (
      <div className="loader" style={{ display: loading ? "flex" : "none" }}>
        <div className="circle"></div>
      </div>
    );
  };
  return <>{renderLoader()}</>;
};
export default Loader;
