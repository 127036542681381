const Song_Details = ({ playing_song }) => {
  return (
    <div className="song-details">
      <h1>{playing_song.name}</h1>
      <h2>{playing_song.artist}</h2>

      <p>
        Producer: <span>{playing_song.producer}</span>
      </p>
      <p>
        Composer: <span>{playing_song.composer}</span>
      </p>
      <p>
        Featured: <span>{playing_song.featured}</span>
      </p>
      <p>
        Songwriter: <span>{playing_song.songwriter}</span>
      </p>
      <p>
        Genre: <span>{playing_song.genre}</span>
      </p>
    </div>
  );
};

export default Song_Details;
