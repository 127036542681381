import { useSelector } from "react-redux";
import axios from "axios";
import FLASH from "../redux/actions/Flash";
import Popup from "./Popup";
import AdminActions from "../redux/actions/AdminActions";
import Functions from "../abstracts/Functions";
import TimeAgo from "react-timeago";

const OrdersCard = ({
  buyer,
  display,
  setDisplay,
  dispatch,
  orderNumber,
  customerName,
  customerEmail,
  customerPhone,
  dateOrdered,
  itemsInOrder,
  totalPrice,
  status,
}) => {
  // Getting values from redux state
  const flash = useSelector((state) => state.flash);

  // Deleting orders

  const deleteOrder = (e) => {
    e.preventDefault();
    setDisplay(true);

    axios({
      url: `${Functions.getAPI()}/orders/delete/${orderNumber}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        dispatch(FLASH({ success: data.success, warn: "", err: "" }));
        dispatch(AdminActions.REMOVE_ORDER(orderNumber));
      })
      .catch((err) => {
        const res = err.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });
  };

  // Render orders
  const renderOrders = () => {
    const renderImage = (item) => {
      if (item.image !== "") {
        return <img src={item.image} alt="Product" />;
      } else {
        return (
          <p style={{ fontSize: "24px" }}>
            <i className="fas fa-headphones"></i>
          </p>
        );
      }
    };
    return (
      <div className={buyer ? "card buyer-card" : "card"}>
        <h2>
          Order Number: <span> {orderNumber} </span>
        </h2>

        <div className="heading">
          <h2>Order Details:</h2>
        </div>

        <div className="order-details">
          <p>
            <span>Name:</span> {customerName}
          </p>
          <p>
            <span>E-mail:</span> {customerEmail}
          </p>
          <p>
            <span>Phone:</span> {customerPhone}
          </p>

          <p>
            <span>Date of Order:</span>{" "}
            <TimeAgo date={parseInt(1641676275986)} />
          </p>
        </div>

        <div className="heading">
          <h2>Items in this order:</h2>
        </div>

        <div className="items">
          {itemsInOrder.map((item) => (
            <a
              key={item._id}
              href={`/playing/${item.songId}`}
              className="items-card"
            >
              <div className="card-img">{renderImage(item)}</div>
              <div className="card-text">
                <h1>{item.name}</h1>
                <p>{item.artist}</p>
                <p className="price">{`R${item.price}`}</p>
              </div>
              {/* <div className="qty">
                <p>[{item.qty}]</p>
              </div> */}
            </a>
          ))}
        </div>

        <div className="heading">
          <h2>Status:</h2>
        </div>

        <h3 className={`total ${status.match("pending") ? "warn" : "success"}`}>
          {status.toUpperCase()}
        </h3>

        <div className="heading">
          <h2>Total:</h2>
        </div>

        <h3 className="total">R{totalPrice}</h3>

        <form method="POST">
          <button
            onClick={(e) => {
              deleteOrder(e);
            }}
            className="delete"
            type="submit"
          >
            Delete
          </button>
        </form>
      </div>
    );
  };
  return (
    //   If orders are found
    <>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      {renderOrders()}
    </>
  );
};

export default OrdersCard;
