import { Link } from "react-router-dom";

const Footer = () => {
  const date = new Date().getFullYear();
  return (
    // Footer
    <footer>
      <div className="footer-card-container">
        <div className="footer-card">
          <h2>Have a question?</h2>
          <Link className="button" to="/contact">
            Contact support
          </Link>
          <p style={{ color: "#bbb", marginTop: "10px", textAlign: "center" }}>
            <i className="fas fa-phone"></i> +27640009442
          </p>
          <p style={{ color: "#bbb", marginTop: "10px", textAlign: "center" }}>
            <i className="fas fa-map-marker-alt"></i> Lebowakgomo Zone F
            <br />
            Polokwane, Limpopo <br />
            SA
          </p>
        </div>
        <div className="footer-card">
          <h2>Quick links</h2>
          <Link className="link" to="/about">
            About
          </Link>
          <Link className="link" to="/#pricing">
            Pricing
          </Link>
          {/* <Link className="link" to="/help">
            FAQ
          </Link> */}
          <a
            target="_blank"
            rel="noreferrer"
            className="link"
            href="https://www.facebook.com/katwavebeats"
          >
            <i className="fab fa-facebook-f"></i> Facebook
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="link"
            href="https://www.instagram.com/katlego_man"
          >
            <i className="fab fa-instagram"></i>Instagram
          </a>
          <a
            className="link"
            rel="noreferrer"
            target="_blank"
            href="https://www.youtube.com/channel/UCHBQDb7wynQmWSQzYUC_3Hw"
          >
            <i className="fab fa-youtube"></i>YouTube
          </a>
        </div>
      </div>
      <p className="copyright raise">
        &copy; <span className="footerYear">{date}</span> All rights reserved |
        Katwave Music
      </p>
    </footer>
  );
};

export default Footer;
