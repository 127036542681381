import axios from "axios";
import { createRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../abstracts/Functions";
import Popup from "../assets/Popup";
import FLASH from "../redux/actions/Flash";

const Book = ({ history }) => {
  // Page information
  Functions.pageInfo({
    title: `Book Katwave`,
    desc: "Booking katwave to perform",
  });

  // use states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [venue, setVenue] = useState("");
  const [type, setType] = useState("");
  const [volume, setVolume] = useState("");
  const [startingTime, setStartingTime] = useState("");
  const [atmosphere, setAtmosphere] = useState("");
  const [display, setDisplay] = useState(false);

  // Dispatch
  const dispatch = useDispatch();

  // Redux state
  const flash = useSelector((state) => state.flash);

  // Input name fields
  const inputNames = [
    "name",
    "email",
    "phone",
    "date",
    "company",
    "location",
    "venue",
    "type",
    "volume",
    "startingTime",
    "atmosphere",
  ];

  // Creating refs
  const nameRef = createRef();
  const emailRef = createRef();
  const phoneRef = createRef();
  const dateRef = createRef();
  const companyRef = createRef();
  const locationRef = createRef();
  const venueRef = createRef();
  const typeRef = createRef();
  const volumeRef = createRef();
  const startingTimeRef = createRef();
  const atmosphereRef = createRef();

  // List of all refs
  const refs = [
    nameRef,
    emailRef,
    phoneRef,
    dateRef,
    companyRef,
    locationRef,
    venueRef,
    typeRef,
    volumeRef,
    startingTimeRef,
    atmosphereRef,
  ];

  // List of all state values
  const vals = [
    name,
    email,
    phone,
    date,
    company,
    location,
    venue,
    type,
    volume,
    startingTime,
    atmosphere,
  ];
  const setVals = [
    setName,
    setEmail,
    setPhone,
    setDate,
    setCompany,
    setLocation,
    setVenue,
    setType,
    setVolume,
    setStartingTime,
    setAtmosphere,
  ];

  // On change
  const onChange = (e) => {
    const { value, name } = e.target;
    for (let i = 0; i < inputNames.length; i++) {
      if (name === inputNames[i]) {
        setVals[i](value);
      }
    }
  };

  // Submit
  const submit = (e) => {
    e.preventDefault();

    // Show popup message
    setDisplay(true);

    // Setting form data
    const data = {};
    for (let i = 0; i < inputNames.length; i++) {
      data[inputNames[i]] = vals[i];
    }

    axios({
      url: `${Functions.getAPI()}/book`,
      method: "POST",
      withCredentials: true,
      data,
    })
      .then(async (res) => {
        const data = await res.data;

        // Dispatching
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          history.push("/error");
          console.log(e);
        }
      });
  };

  return (
    <div className="register-login">
      {/* Popups */}
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
          history={history}
          redirect="/"
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      <div className="form">
        <div className="heading">
          <h1>Booking info</h1>
        </div>
        <form className="form-slide" method="POST">
          <input
            type="text"
            className="formInput"
            name={inputNames[0]}
            ref={refs[0]}
            placeholder={`Enter ${inputNames[0]}`}
            onChange={onChange}
            value={vals[0]}
          />

          <input
            type="text"
            className="formInput"
            name={inputNames[1]}
            ref={refs[1]}
            placeholder={`Enter ${inputNames[1]}`}
            onChange={onChange}
            value={vals[1]}
          />

          <input
            type="text"
            className="formInput"
            name={inputNames[2]}
            ref={refs[2]}
            placeholder={`Enter ${inputNames[2]}`}
            onChange={onChange}
            value={vals[2]}
          />

          <input
            type="text"
            className="formInput"
            name={inputNames[3]}
            ref={refs[3]}
            placeholder={`Enter event date`}
            onChange={onChange}
            value={vals[3]}
          />
          <p style={{ color: "#bbb" }}>Format: DD/MM/YYYY</p>

          <input
            type="text"
            className="formInput"
            name={inputNames[4]}
            ref={refs[4]}
            placeholder={`Enter ${inputNames[4]}`}
            onChange={onChange}
            value={vals[4]}
          />
          <p style={{ color: "#bbb" }}>(Optional)</p>

          <input
            type="text"
            className="formInput"
            name={inputNames[5]}
            ref={refs[5]}
            placeholder={`Enter event location`}
            onChange={onChange}
            value={vals[5]}
          />
          <p style={{ color: "#bbb" }}>Format: town, city, province</p>

          <input
            type="text"
            className="formInput"
            name={inputNames[6]}
            ref={refs[6]}
            placeholder={`Enter event venue`}
            onChange={onChange}
            value={vals[6]}
          />

          <input
            type="text"
            className="formInput"
            name={inputNames[7]}
            ref={refs[7]}
            placeholder={`Enter event type`}
            onChange={onChange}
            value={vals[7]}
          />
          <p style={{ color: "#bbb" }}>E.g Wedding, Concert etc</p>

          <select
            name={inputNames[8]}
            value={vals[8]}
            onChange={onChange}
            ref={refs[8]}
          >
            <option value="">No. of people expected:</option>
            <option value="1-50">1 to 50</option>
            <option value="50-100">50 to 100</option>
            <option value="100-200">100 to 200</option>
            <option value="200-300">200 to 300</option>
            <option value="300-400">300 to 400</option>
            <option value="500+">More than 500</option>
          </select>

          <input
            type="text"
            className="formInput"
            name={inputNames[9]}
            ref={refs[9]}
            placeholder={`Enter starting time of performance`}
            onChange={onChange}
            value={vals[9]}
          />
          <p style={{ color: "#bbb" }}>E.g 7PM etc</p>

          <select
            name={inputNames[10]}
            value={vals[10]}
            onChange={onChange}
            ref={refs[10]}
          >
            <option value="">Performance is:</option>
            <option value="indoor">Indoor</option>
            <option value="outdoor">Outdoor</option>
          </select>
          <br />

          <button onClick={submit} type="submit" className="submit-button">
            Submit
          </button>
          <br />
        </form>
      </div>
    </div>
  );
};
export default Book;
