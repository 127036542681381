// import images from "../abstracts/Images";

import { useEffect } from "react";

const CartItem = ({
  name,
  artist,
  price,
  leaseType,
  leaseTypeVal,
  image,
  _id,
  currency,
  updateCart,
  removeItem,
  setLeaseType,
}) => {
  useEffect(() => {
    setLeaseType(leaseType);
  }, [setLeaseType, leaseType]);
  return (
    <div className="card">
      <div
        className="delete"
        onClick={(e) => {
          removeItem(e, _id);
        }}
      >
        <p>X</p>
      </div>
      <div className="img">
        {image ? (
          <img src={image} alt="art" />
        ) : (
          <p>
            <i className="fas fa-headphones"></i>
          </p>
        )}
      </div>

      <div className="details">
        <h1>{name}</h1>
        <h2>{artist}</h2>

        <p className="price">
          {currency}
          {price}
        </p>
      </div>

      <select
        name="leaseType"
        value={leaseType}
        id="leaseType"
        onChange={(e) => {
          updateCart(e, _id);
        }}
      >
        <option value="basic">Basic Lease</option>
        <option value="premium">Premium Lease</option>
        <option value="unlimited">Unlimited Lease</option>
        <option value="exclusive">Exclusive Rights</option>
      </select>
    </div>
  );
};

export default CartItem;
