import { Route } from "react-router";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ForgotPass from "../pages/ForgotPass";
import ResetPass from "../pages/ResetPass";
import ActivateAcc from "../pages/ActivateAcc";

const Auth = [
  {
    routeType: Route,
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    routeType: Route,
    path: "/buyer-login",
    exact: true,
    component: Login,
  },
  {
    routeType: Route,
    path: "/register",
    exact: true,
    component: Register,
  },
  {
    routeType: Route,
    path: "/activate/:userId/:userToken",
    exact: true,
    component: ActivateAcc,
  },
  {
    routeType: Route,
    path: "/forgot-password",
    exact: true,
    component: ForgotPass,
  },
  {
    routeType: Route,
    path: "/reset-password/:token",
    exact: true,
    component: ResetPass,
  },
];

export default Auth;
