import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Functions from "../abstracts/Functions";
import AdminNav from "../assets/AdminNav";
import Form from "../assets/Form";
import Loader from "../assets/Loader";
import Popup from "../assets/Popup";
import FLASH, { RESTORE_FLASH } from "../redux/actions/Flash";
import SongActions from "../redux/actions/SongActions";

const SongCover = ({ history }) => {
  // Page information
  Functions.pageInfo({
    title: "Add A Song Cover",
    desc: "Artist Adding Song Cover For The Uploaded Song",
  });

  // Use state
  const [file, setFile] = useState(null);
  const [src, setSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [flashDisplay, setFlashDisplay] = useState(false);

  // Redux
  const dispatch = useDispatch();

  const flash = useSelector((state) => state.flash);

  // Get data from the params (i.e /id)
  const { id } = useParams();

  // File reader
  const reader = new FileReader();

  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Handle file change
  const onChange = async (e) => {
    // Getting the currently uploaded file
    const file = await e.target.files[0];

    // Setting the file name
    setFile(file);

    // If there's a file selected
    if (file) {
      const checkFileType = /image/i;
      // If the file type is an image
      if (checkFileType.test(file.type)) {
        // Reads the uploaded file as dataUrl
        reader.readAsDataURL(file);

        reader.onload = async (e) => {
          // This is the uploaded file as DataUrl
          const uploadedFile = e.target.result;

          // Set src for the file to display after upload
          setSrc(uploadedFile);
        };
      } else {
        console.log("Only images allowed!");
        //   Showing the popup
        setFlashDisplay(true);

        // Display a popup
        dispatch(FLASH({ err: "", success: "", warn: "Only images allowed!" }));
      }
    } else {
      // If no image is uploaded, set the img source to null
      setFile(null);
    }
  };

  // Upload the selected image
  const upload = (e) => {
    e.preventDefault();

    // Setting form data
    const fd = new FormData();
    fd.append("cover", file);

    // Config (headers etc)
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = `${Functions.getAPI()}/song/cover/${id}`;

    //   Send request
    const request = () => {
      // Showing loader
      setLoading(true);

      axios
        .post(url, fd, config)
        .then(async (res) => {
          const data = res.data;
          // If status is 200, let the user login
          if (res.status === 200) {
            // Removing loader
            setLoading(false);

            // Display popup
            setFlashDisplay(true);

            console.log("response", data);

            // Redirect to the cover upload page
            history.push(`/`);

            // Dispatching
            dispatch(FLASH({ err: "", success: data.success, warn: "" }));
            dispatch(
              SongActions.ADD_SONG_COVER({ id: id, cover: data.song.cover })
            );
          }
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            if (res.data.err) {
              // Removing loader
              setLoading(false);

              // Display popup
              setFlashDisplay(true);

              if (res.data.err === "File too large! (Should be 3mb or less)") {
                setSrc(null);
                setFile(null);
              }

              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              // Removing loader
              setLoading(false);

              // Display popup
              setFlashDisplay(true);

              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
            console.log(res.data);
          } else {
            history.push("/error");
            console.log(e);
          }
        });
    };

    if (!file) {
      // Display a popup
      setFlashDisplay(true);

      // Dispatching
      dispatch(FLASH({ warn: "No file uploaded!", err: "", success: "" }));
    } else {
      request();
    }
  };

  // Skip the song cover upload
  const skip = (e) => {
    e.preventDefault();

    history.push(`/admin/songs`);
  };

  // Handling dragging and dropping

  const dragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const dragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(false);
    }
  };

  const drag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const drop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // Getting the currently uploaded file
      const file = await e.dataTransfer.files[0];

      // Setting the file name
      setFile(file);

      // Clearing the data from dataTransfer to be left
      // with the file just uploaded
      e.dataTransfer.clearData();

      // If there's a file selected
      if (file) {
        // If the file type is an image
        if (file.type.match(/image/i)) {
          // Reads the uploaded file as dataUrl
          reader.readAsDataURL(file);

          reader.onload = async (e) => {
            // This is the uploaded file as DataUrl
            const uploadedFile = e.target.result;

            // Set src for the file to display after upload
            setSrc(uploadedFile);
          };
        } else {
          //   Showing the popup
          setFlashDisplay(true);

          // Display a popup
          dispatch(
            FLASH({ err: "", success: "", warn: "Only images allowed!" })
          );
        }
      } else {
        // If no image is uploaded, set the img source to null
        setFile(null);
      }
    }
  };

  // Undo file upload in order to upload again
  const undo = (e) => {
    e.preventDefault();
    setSrc(null);
  };

  const renderLoader = () => {
    const mainColor = "#d41b43ff";
    return (
      <>
        {/* Admin Nav */}
        <AdminNav page="upload" />

        <Form className="expand">
          {/* <div className="heading">
          <h1>Song cover</h1>
        </div> */}
          <input type="file" id="cover" onChange={onChange} />
          <label
            className="drag-drop"
            htmlFor="cover"
            style={{
              color: "#777",
              border: !dragging ? "3px dashed #777" : `3px dashed ${mainColor}`,
              display: src ? "none" : "flex",
            }}
            onDragEnter={dragIn}
            onDragLeave={dragOut}
            onDragOver={drag}
            onDrop={drop}
          >
            <i className="fas fa-cloud-upload"></i>
            Click here or drag-drop to upload cover art{" "}
          </label>

          <img
            style={{ display: src ? "flex" : "none" }}
            src={src}
            alt="cover"
            loading="lazy"
          />

          <button
            onClick={upload}
            style={{ display: src ? "flex" : "none", marginTop: "10px" }}
          >
            Save
          </button>
          <button
            style={{ display: !src ? "flex" : "none" }}
            className="drop-btn dry"
            onClick={skip}
          >
            Skip
          </button>
          <button
            style={{ display: src ? "flex" : "none" }}
            className="drop-btn dry"
            onClick={undo}
          >
            Undo
          </button>
        </Form>
      </>
    );
  };
  return (
    <>
      {/* Loader */}
      <Loader loading={loading} />

      {/* Popups */}
      {flash.err !== "" && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          err={flash.err}
        />
      )}
      {flash.success !== "" && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          success={flash.success}
          history={history}
        />
      )}
      {flash.warn !== "" && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          warn={flash.warn}
        />
      )}
      {renderLoader()}
    </>
  );
};
export default SongCover;
