const Form = ({ className, children }) => {
  return (
    <div className="register-login">
      <div className="form">
        <form
          className={className ? `form-slide ${className}` : `form-slide`}
          method="POST"
        >
          {children}
        </form>
      </div>
    </div>
  );
};
export default Form;
