import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import images from "../abstracts/Images";
import axios from "axios";
import AuthActions from "../redux/actions/AuthActions";
import USER from "../redux/actions/User";
import Functions from "../abstracts/Functions";

const Menu = ({ menu, toggleMenu }) => {
  const date = new Date().getFullYear();
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const pp =
    user.profilePic && user.profilePic.Location
      ? user.profilePic.Location
      : images.avatar;
  const playing_song = useSelector((st) => st.playing_song);

  // History
  const history = useHistory();

  // Dispatch
  const dispatch = useDispatch();

  // Logging out function
  const logout = (e) => {
    e.preventDefault();
    axios({
      url: `${Functions.getAPI()}/logout`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // If status is 200, let the user login
        if (res.status === 200) {
          // Dispatching
          dispatch(AuthActions.logout());
          dispatch(USER.DELETE_ACCOUNT());
          history.push("/login");
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div
      className="mobile-menu"
      onClick={toggleMenu}
      style={{ display: menu ? "flex" : "none" }}
    >
      <div className="links">
        <div className="user">
          <img src={pp} alt="user" />
          <Link
            onClick={(e) => e.preventDefault()}
            to={user._id ? `/profile/${user.username}` : "/login"}
          >
            {user._id ? user.email : "Not logged in!"}
          </Link>
        </div>
        <Link to="/">
          <i className="fa fa-home" aria-hidden="true">
            {" "}
          </i>
          Home
        </Link>
        {user._id && (
          <Link to="/cart">
            <i className="fa fa-shopping-bag" aria-hidden="true"></i>
            Cart [ {cart.items.length} ]
          </Link>
        )}
        {playing_song._id && (
          <Link to={`/playing/${playing_song._id}`}>
            <i className="fas fa-align-left playing"></i>
            Now playing
          </Link>
        )}
        <Link to="/beats">
          <i className="fas fa-headphones"></i>
          Beats
        </Link>
        <Link to="/tracks">
          <i className="fas fa-headphones"></i>
          Tracks
        </Link>
        {user.admin && (
          <Link to="/admin/songs">
            <i className="fa fa-table" aria-hidden="true"></i>
            Admin
          </Link>
        )}
        <Link to="/book">
          <i className="fas fa-file"></i>
          Book Me
        </Link>
        {user.admin && (
          <Link to="/admin/bookings">
            <i className="fas fa-file"></i>
            Bookings
          </Link>
        )}
        {/* <Link to="/help">
          <i className="fas fa-question-circle"></i>
          Help
        </Link> */}
        <Link to="/about">
          <i className="fas fa-info-circle"></i>
          About
        </Link>
        <Link to="/contact">
          <i className="fas fa-phone-square"></i>
          Contact
        </Link>
        {user.email ? (
          <Link onClick={logout} to="/logout" className="ctaBtn">
            <i className="fas fa-sign-out"></i>
            Logout
          </Link>
        ) : (
          <Link to="/login" className="ctaBtn">
            <i className="fas fa-sign-in"></i>
            Login
          </Link>
        )}

        <div className="footer">
          <p>@ {date} | All rights reserved</p>
        </div>
      </div>
    </div>
  );
};
export default Menu;
