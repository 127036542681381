const HOME_DATA = (
  state = {
    topBeats: {},
    topTracks: [],
  },
  { type, payload }
) => {
  switch (type) {
    case "HOME_DATA":
      return payload;
    default:
      return state;
  }
};
export default HOME_DATA;
