import { useDispatch, useSelector } from "react-redux";
import Popup from "../assets/Popup";
import FLASH, { RESTORE_FLASH } from "../redux/actions/Flash";
import Content from "../components/home/Content";
import Welcome from "../components/home/Welcome";
import HomeActions from "../redux/actions/HomeActions";
import axios from "axios";
import { useState, useEffect } from "react";
import Functions from "../abstracts/Functions";

const Home = () => {
  // Page information
  Functions.pageInfo({
    title: "Katwave | Your next big thing starts here!",
    desc: "Home page",
  });

  // // Redux state
  const flash = useSelector((state) => state.flash);
  const home_data = useSelector((state) => state.home_data);

  // // Redux disparch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    dispatch(RESTORE_FLASH());

    axios({
      method: "GET",
      url: `${Functions.getAPI()}/song/top/all`,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(HomeActions.HOME_DATA(data));
      })
      .catch((e) => {
        // Getting the response if it is set on server
        const res = e.response;
        if (res) {
          const data = res.data;
          if (data.err) {
            // Dispatching
            dispatch(FLASH({ err: data.err, success: "", warn: "" }));
          } else if (data.warn) {
            dispatch(FLASH({ err: "", success: "", warn: data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  }, [dispatch]);

  const renderHome = () => {
    return (
      <div>
        {flash.err !== "" && (
          <Popup display={display} setDisplay={setDisplay} err={flash.err} />
        )}
        {flash.warn !== "" && (
          <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
        )}
        {flash.success !== "" && (
          <Popup
            display={display}
            setDisplay={setDisplay}
            success={flash.success}
          />
        )}
        <Welcome />
        {home_data.topBeats && <Content setDisplay={setDisplay} />}
      </div>
    );
  };
  return renderHome();
};

export default Home;
