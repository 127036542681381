import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AudioSettings from "../abstracts/AudioSettings";
import FLASH, { RESTORE_FLASH } from "../redux/actions/Flash";
import Popup from "./Popup";

const SongAudio = ({ setFile, upload, nextBack, setStartPosition }) => {
  // States
  const [fillSliderRef, setfillSliderRef] = useState(null);
  const [canvasRef, setCanvasRef] = useState(null);
  const [playRef, setPlayRef] = useState(null);
  const [seekRef, setSeekRef] = useState(null);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [seekVal, setSeekVal] = useState(100);
  const [volumeVal, setVolumeVal] = useState(100);
  const [uploaded, setUploaded] = useState(false);
  const [flashDisplay, setFlashDisplay] = useState(false);

  // Getting values from redux state
  const flash = useSelector((state) => state.flash);

  // Dispatch
  const dispatch = useDispatch();

  // UseEffect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Audio logic

  // Audio playlist
  const [audioFile, setAudioFile] = useState(null);

  // Creating the audio element
  const [audio] = useState(new Audio());

  useEffect(() => {
    if (audioFile) {
      audio.src = audioFile;
    }
  }, [audioFile, audio]);

  useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio]);

  // Audio settings
  const audioSettings = new AudioSettings(audio);

  // Handling refs as functions
  const fillSlider = (e) => {
    setfillSliderRef(e);
  };
  const canvas = (e) => {
    setCanvasRef(e);
  };
  const play = (e) => {
    setPlayRef(e);
  };
  const seek = (e) => {
    setSeekRef(e);
  };

  // Audio states
  const [startTime, setStartTime] = useState(0);
  const [playing, setPlaying] = useState("Upload audio file to start!");
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");
  const [playPause, setPlayPause] = useState("play");

  // Styles to use
  const onColor = "rgb(41, 250, 138)";
  const offColor = "#fff";
  const onShadow =
    " inset 1px 2px 4px rgba(0, 0, 0, 0.9), inset 1px 0px 1px rgba(255, 255, 255, 0.9)";
  const offShadow = "  0px 2px 2px rgba(0, 0, 0, 0.7)";

  // Toggles
  const toggleUploaded = () => {
    setUploaded(!uploaded);
  };

  // Icons from audio settings
  const icon = (icon) => {
    return audioSettings.audIcons(icon);
  };

  // Sliders
  const cutSlider = () => {
    return audioSettings.cutSlider(fillSliderRef, {
      sliderRGBColor: {
        r: "131",
        g: "49",
        b: "49",
      },
      thumb: true,
      thumbWidth: "3",
      thumbColor: "#000",
      aud: { start: startTime, end: 30 },
    });
  };

  // When an audio file is uploaded
  const uploadAudio = async (e) => {
    // Getting the uploaded audio file
    const uploaded_file = await e.target.files[0];

    // Splitting the file's name without extention
    const spl = uploaded_file.name.split(".");
    const filename = spl[0];
    const ext = spl[1];

    if (ext === "mp3") {
      setUploaded(true);

      // Creating a binary large object (blob) from the file
      const blob = URL.createObjectURL(uploaded_file);

      // Setting the file to play as a blob
      setAudioFile(blob);

      // Setting the file to be uploaded
      setFile(uploaded_file);

      // Playing the audio after upload
      audio.play();

      // Displaying the file's name
      setPlaying(filename);
    } else {
      setUploaded(false);
      setFlashDisplay(true);
      dispatch(
        FLASH({ warn: "Only mp3 files allowed!", success: "", err: "" })
      );
    }
  };

  // Showing current time and duration for the current audio after page loads
  // And also updating the UI
  audio.onloadedmetadata = () => {
    setCurrentTime(audioSettings.getCurrentTime());
    setDuration(audioSettings.getDuration());

    // If paused show "play" otherwise show "pause"
    if (audio.paused) {
      setPlayPause(icon("play"));
      playRef.style = `color: ${onColor}; box-shadow: ${onShadow}`;
    } else {
      setPlayPause(icon("pause"));
    }

    // Drawing the waveform of a playing song
    audioSettings.drawWaveform(canvasRef, {
      color: "#fff",
      background: "rgb(46,46,58)",
    });

    // Fill sliders

    cutSlider();
  };

  // Playing and pausing audio
  const PlayAndPause = () => {
    setAudioPlayed(!audioPlayed);
    if (audioFile) {
      if (audioPlayed) {
        audio.pause();
        playRef.style = `color: ${offColor}; box-shadow: ${offShadow}`;
        setPlayPause(icon("play"));
      } else {
        audio.play();
        playRef.style = `color: ${onColor}; box-shadow: ${onShadow}`;
        setPlayPause(icon("pause"));
      }
    }
  };

  // Volume change
  const changeVolume = (e) => {
    if (audioFile) {
      setVolumeVal(e.target.value);
      audioSettings.changeVolume(volumeVal);
    }
  };

  // Seeking audio position
  const audioSeek = (e) => {
    if (audioFile) {
      setSeekVal(e.target.value);
      audioSettings.changeAudPosition(e.target.value);

      // Getting the position of audio
      setStartTime(Math.floor(audio.currentTime));
      setStartPosition(Math.floor(audio.currentTime));

      // Fill sliders

      cutSlider();
    }
  };

  audio.ontimeupdate = () => {
    // updating seek value when current time changes
    seekRef.value = audioSettings.updateAudTime();

    // Fill sliders

    cutSlider();

    // Showing current time and duration for the current audio
    setCurrentTime(audioSettings.getCurrentTime());

    // Loop audio
    audio.loop = true;
  };

  return (
    <>
      {flash.err && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          err={flash.err}
        />
      )}
      {flash.warn && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          warn={flash.warn}
        />
      )}
      {flash.success && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          success={flash.success}
        />
      )}
      <div className="audio-upload">
        <div className="head">
          <div className="upload">
            <input onChange={uploadAudio} type="file" name="file" id="file" />
            <label
              onClick={toggleUploaded}
              htmlFor="file"
              style={{ display: !uploaded ? "flex" : "none" }}
            >
              <i className="fa fa-cloud-upload" aria-hidden="true">
                {" "}
              </i>{" "}
              Upload
            </label>
          </div>
          <p id="playing">{playing}</p>
        </div>
        <div className="controls">
          <button id="play" ref={play} onClick={PlayAndPause}>
            <i className={`fa fa-${playPause}`} aria-hidden="true"></i>
          </button>
        </div>
        <br />
        <div className="ranges">
          <div className="volume-container">
            <p>
              <i className="fa fa-volume-up" aria-hidden="true"></i>
            </p>
            <input
              type="range"
              name="volume"
              id="volume"
              min="0"
              max="100"
              value={volumeVal}
              step="1"
              onInput={changeVolume}
            />
          </div>
        </div>
        <br />

        <div className="times">
          <h3>
            <span id="currentTime">{currentTime}</span>
          </h3>
          <span className="line"></span>
          <h3>
            <span id="duration">{duration}</span>
          </h3>
        </div>

        <div className="seek-container">
          <input
            type="range"
            name="seek"
            id="seek"
            min="0"
            max="100"
            value={seekVal}
            step="1"
            ref={seek}
            onInput={audioSeek}
            onClick={audioSeek}
          />
          <div id="fillSlider" ref={fillSlider}></div>
          <canvas ref={canvas}> </canvas>
        </div>

        <div className="buttons">
          <button className="back" onClick={nextBack}>
            Back
          </button>
          <button onClick={upload}>Save</button>
        </div>
      </div>
    </>
  );
};

export default SongAudio;
