const SongCardLoader = () => {
  return (
    <div className="song-card-loader">
      <div className="art"></div>
      <div className="details">
        <h1>hshdghsgdhsgdhjfhskfhjshfjhsfjj</h1>
        <h2>hshdghsgdhs</h2>
      </div>
    </div>
  );
};

export default SongCardLoader;
