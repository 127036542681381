import { Link } from "react-router-dom";
import AuthActions from "../redux/actions/AuthActions";
import USER from "../redux/actions/User";
import Functions from "../abstracts/Functions";
import axios from "axios";
import { useDispatch } from "react-redux";

const DesktopNavLinks = ({ link, displayLinks, toggleNavLinks }) => {
  // Dispatch
  const dispatch = useDispatch();

  // Logging out function
  const logout = (e) => {
    e.preventDefault();
    axios({
      url: `${Functions.getAPI()}/logout`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Dispatching
        dispatch(AuthActions.logout());
        dispatch(USER.DELETE_ACCOUNT());
        localStorage.setItem("auth", false);
      })
      .catch((e) => console.log(e));
  };

  // Links to display on the popup menu
  const musicLinks = () => {
    return (
      <>
        <Link to="/tracks">Tracks</Link>
        <Link to="/beats">Beats</Link>
      </>
    );
  };
  const devLinks = () => {
    return (
      <>
        <Link to="/resume">Resume</Link>
        <Link to="/projects">Projects</Link>
      </>
    );
  };
  const accLinks = () => {
    return (
      <>
        <Link to="/logout" onClick={logout}>
          Logout
        </Link>
      </>
    );
  };

  // Showing desktop nav links
  const showLinks = () => {
    if (link === "music") {
      return musicLinks();
    } else if (link === "dev") {
      return devLinks();
    } else if (link === "acc") {
      return accLinks();
    }
  };

  return (
    <div
      className="desktop-navLinks-cont"
      style={displayLinks ? { display: "flex" } : { display: "none" }}
    >
      <div className="close">
        <button onClick={toggleNavLinks}>X</button>
      </div>
      <div className="links">{showLinks()}</div>
    </div>
  );
};

export default DesktopNavLinks;
