import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Functions from "../abstracts/Functions";
import Popup from "../assets/Popup";
import SongCard from "../assets/SongCard";
// import { useEmblaCarousel } from "embla-carousel";

const Beats = () => {
  // Genres
  const genres = ["hip-hop", "house", "edm", "rnb", "jazz", "reggae", "pop"];

  // Use selector
  const flash = useSelector((s) => s.flash);

  // Use state
  const [songs, setSongs] = useState([]);
  const [display, setDisplay] = useState([]);
  const [genre, setGenre] = useState("");

  const param = useLocation();

  // Use effect
  useEffect(() => {
    setGenre(param.search.replace("?", ""));

    axios({
      url: `${Functions.getAPI()}/songs/beats?genre=${genre}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        const data = await res.data;

        setSongs(data.songs);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  }, [genre, param.search]);

  // Render song cards
  const renderSongs = () => {
    if (songs.length > 0) {
      return songs.map((song, ind) => (
        <SongCard
          key={ind}
          _id={song._id}
          name={song.name}
          artist={song.artist}
          img={song.cover.Location}
          plays={song.plays}
          downloads={song.downloads}
          audioFile={song.audio.Location}
          type={song.type}
          setDisplay={setDisplay}
        />
      ));
    } else {
      return <h2 className="not-found">No beats found!</h2>;
    }
  };

  return (
    <div className="main-cont">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}

      <div className="songs-cont">
        <div className="heading">
          <h1>Beats playlist</h1>
        </div>

        <div className="genres">
          <div className="genres__container">
            {genres.map((i, ind) => (
              <Link
                key={ind}
                to={`/beats?${i}`}
                onClick={() => {
                  setGenre(i);
                }}
                className={`genre ${genre === i ? "active" : ""}`}
              >
                {i}
              </Link>
            ))}
          </div>
        </div>

        {renderSongs()}
      </div>
    </div>
  );
};

export default Beats;
