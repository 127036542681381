import axios from "axios";
import { useState } from "react";
import { createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SongAudio from "../assets/SongAudio";
import Popup from "../assets/Popup";
import Functions from "../abstracts/Functions";
import FLASH from "../redux/actions/Flash";
import SongActions from "../redux/actions/SongActions";
import Loader from "../assets/Loader";
import SongForm from "../assets/SongForm";
import AdminNav from "../assets/AdminNav";

const UploadSong = ({ history }) => {
  // Page information
  Functions.pageInfo({
    title: `Upload Your Next Hit Song`,
    desc: "User Uploading A Song",
  });

  // Use state
  const [genre, setGenre] = useState("");
  const [name, setName] = useState("");
  const [artist, setArtist] = useState("");
  const [featured, setFeatured] = useState("");
  const [songwriter, setSongwriter] = useState("");
  const [producer, setProducer] = useState("");
  const [composer, setComposer] = useState("");
  const [type, setType] = useState("");
  const [next, setNext] = useState(false);
  const [flashDisplay, setFlashDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [startPosition, setStartPosition] = useState(0);

  // Dispatch
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  // Redux state
  const flash = useSelector((state) => state.flash);

  // Input name fields
  const inputNames = [
    "name",
    "artist",
    "featured",
    "songwriter",
    "producer",
    "composer",
    "genre",
    "type",
  ];

  // Creating refs
  const genreRef = createRef();
  const nameRef = createRef();
  const artistRef = createRef();
  const featuredRef = createRef();
  const songwriterRef = createRef();
  const producerRef = createRef();
  const composerRef = createRef();
  const typeRef = createRef();

  // List of all refs
  const refs = [
    nameRef,
    artistRef,
    featuredRef,
    songwriterRef,
    producerRef,
    composerRef,
    genreRef,
    typeRef,
  ];

  // List of all state values
  const vals = [
    name,
    artist,
    featured,
    songwriter,
    producer,
    composer,
    genre,
    type,
  ];
  const setVals = [
    setName,
    setArtist,
    setFeatured,
    setSongwriter,
    setProducer,
    setComposer,
    setGenre,
    setType,
  ];

  // On change
  const onChange = (e) => {
    const { value, name } = e.target;
    for (let i = 0; i < inputNames.length; i++) {
      if (name === inputNames[i]) {
        setVals[i](value);
      }
    }
  };

  // Toggling next and back
  const nextBack = (e) => {
    e.preventDefault();
    setNext(!next);
  };

  // Upload the song
  const upload = (e) => {
    e.preventDefault();

    // Setting form data
    const fd = new FormData();
    for (let i = 0; i < inputNames.length; i++) {
      fd.append(inputNames[i], vals[i]);
    }
    fd.append("start", startPosition);
    fd.append("song", file);
    console.log("FIle:", file);

    // Config (headers etc)
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = `${Functions.getAPI()}/song/add/${user._id}`;
    const request = () => {
      // Showing loader
      setLoading(true);

      axios
        .post(url, fd, config)
        .then(async (res) => {
          const data = res.data;
          // If status is 200, let the user login
          if (res.status === 200) {
            // Removing loader
            setLoading(false);

            // Display a popup
            setFlashDisplay(true);

            // Redirect to the cover upload page
            history.push(`/admin/upload/${data.data._id}`);

            // Dispatching
            dispatch(FLASH({ err: "", success: data.success, warn: "" }));
            dispatch(SongActions.ADD_SONG(data.data));
          }
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            if (res.data.err) {
              // Removing loader
              setLoading(false);

              // Display a popup
              setFlashDisplay(true);

              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              // Removing loader
              setLoading(false);

              // Display a popup
              setFlashDisplay(true);

              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
            console.log(res.data);
          } else {
            history.push("/error");
            console.log(e);
          }
        });
    };

    // Making sure all input fields are filled
    const checkCompletion = vals.map((item) => item === "");
    if (checkCompletion.includes(true)) {
      // Display a popup
      setFlashDisplay(true);

      dispatch(
        FLASH({ err: "", warn: "Form incomplete! (Go back)", success: "" })
      );
    } else if (file === "") {
      // Display a popup
      setFlashDisplay(true);

      // Dispatching
      dispatch(FLASH({ warn: "Upload an audio file!", err: "", success: "" }));
    } else {
      request();
    }
  };

  return (
    <>
      {/* Admin Nav */}
      <AdminNav page="upload" />

      {/* Loader */}
      <Loader loading={loading} />

      {/* Popups */}
      {flash.err !== "" && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          err={flash.err}
        />
      )}
      {flash.success !== "" && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          success={flash.success}
          history={history}
        />
      )}
      {flash.warn !== "" && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          warn={flash.warn}
        />
      )}

      <div
        className="register-login"
        style={{ display: !next ? "flex" : "none" }}
      >
        <div className="form login">
          <div className="heading">
            <h1>Song upload</h1>
          </div>

          <SongForm
            nextBack={nextBack}
            refs={refs}
            inputNames={inputNames}
            vals={vals}
            onChange={onChange}
          />
        </div>
      </div>
      {/* Audio uploader */}
      <div
        className="upload-container"
        style={{ display: next ? "flex" : "none" }}
      >
        <SongAudio
          nextBack={nextBack}
          setStartPosition={setStartPosition}
          upload={upload}
          setFile={setFile}
        />
      </div>
    </>
  );
};
export default UploadSong;
