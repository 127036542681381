import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../abstracts/Functions";
import BookingCard from "../assets/BookingCard";
import Popup from "../assets/Popup";
import FLASH from "../redux/actions/Flash";

const Book = ({ history }) => {
  // Page information
  Functions.pageInfo({
    title: `Admin Bookings`,
    desc: "Viewing all bookings",
  });

  // Use states
  const [display, setDisplay] = useState(false);
  const [bookings, setBookings] = useState([]);

  // Dispatch
  const dispatch = useDispatch();

  // Redux state
  const flash = useSelector((state) => state.flash);

  // Get all bookings from server
  useEffect(() => {
    axios({
      url: `${Functions.getAPI()}/admin/bookings`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        const data = await res.data;

        setBookings(data.bookings);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          history.push("/error");
          console.log(e);
        }
      });
  }, [dispatch, history]);

  // Deleting a booking
  const deleteBooking = (e, id) => {
    e.preventDefault();

    //   Show popup message
    setDisplay(true);

    axios({
      url: `${Functions.getAPI()}/booking/delete/${id}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        const data = await res.data;
        console.log("b data:", data);

        setBookings(data.bookings);
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          history.push("/error");
          console.log(e);
        }
      });
  };

  // Render all bookings
  const renderBookings = () => {
    if (bookings.length > 0) {
      return bookings.map((b, ind) => (
        <BookingCard deleteBooking={deleteBooking} key={ind} booking={b} />
      ));
    } else {
      return <h2 className="not-found">No bookings found!</h2>;
    }
  };

  return (
    <div>
      {/* Popups */}
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      <div className="account">
        <h1 className="main-heading">Bookings</h1>

        {/* Users data */}
        <div className="account-container">{renderBookings()}</div>
      </div>
    </div>
  );
};
export default Book;
