import { Link, useHistory } from "react-router-dom";
import images from "../abstracts/Images";
import { useDispatch } from "react-redux";
import Functions from "../abstracts/Functions";
import SongActions from "../redux/actions/SongActions";
import axios from "axios";
import { useState } from "react";

const MobileNav = ({ toggleMenu, search, toggleSearch }) => {
  // Use state
  const [q, setQ] = useState("");

  // History
  const history = useHistory();

  // Dispatch
  const dispatch = useDispatch();

  // When a user types in the search box,
  // set the query value
  const onChange = (e) => {
    setQ(e.target.value);
  };

  // Search a song by name or artist
  const searchSongs = (e) => {
    e.preventDefault();

    if (q) {
      axios({
        url: `${Functions.getAPI()}/songs/search?q=${q}`,
        method: "GET",
        data: { q },
      })
        .then(async (res) => {
          const data = await res.data;
          // If status is 200, let the user login
          if (res.status === 200) {
            // Dispatching
            dispatch(SongActions.SEARCHED_SONGS(data.songs));

            // Redirecting the user
            history.push(`/songs/search?q=${q}`);
          }
        })
        .catch((e) => {
          const res = e.response;
          if (res) console.log(res.data);
          else console.log(e);
        });
    }
  };

  return (
    <nav className="mobile-nav">
      <div className="nav">
        <div className="lines" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="logo">
          <Link to="/">
            <img src={images.logos.logo512} alt="logo" />
          </Link>
        </div>

        <button className="search" onClick={toggleSearch}>
          <i className="fa fa-search" aria-hidden="true">
            {" "}
          </i>
        </button>
      </div>

      <div className="search-box" style={{ display: search ? "flex" : "none" }}>
        <div className="close-search" onClick={toggleSearch}>
          <p>x</p>
        </div>
        <form action="#">
          <input
            onChange={onChange}
            type="text"
            placeholder="Search beats and tracks"
          />
        </form>
        <div className="search-go" onClick={searchSongs}>
          <p>
            <i className="fa fa-arrow-right" aria-hidden="true">
              {" "}
            </i>
          </p>
        </div>
      </div>
    </nav>
  );
};
export default MobileNav;
