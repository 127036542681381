import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Functions from "../abstracts/Functions";
import axios from "axios";

const ActivateAcc = () => {
  // React use states
  const [waiting, setWaiting] = useState(true);
  const [responseText, setResponseText] = useState(
    "Processing your account..."
  );

  // Params
  const { userId, userToken } = useParams();

  // Setting the page info
  Functions.pageInfo({
    title: "Account Activation",
    desc: "User Activating Their Account",
  });

  useEffect(() => {
    axios({
      url: `${Functions.getAPI()}/activate/${userId}/${userToken}`,
      method: "POST",
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // If status is 200
        if (res.status === 200) {
          if (data.success) {
            setResponseText(data.success);
          } else {
            setResponseText(data.warn);
          }
          setWaiting(false);
        }
      })
      .catch((err) => {
        const res = err.response.data;
        if (res.err) {
          setResponseText(res.err);
        } else if (res.warn) {
          setResponseText(res.warn);
        }
        console.log(err);
        console.log(res);
      });
  }, [userId, userToken]);
  return (
    <div className="register-login">
      <div className="form">
        <div className="heading">
          <h1 style={{ textAlign: "center" }}>{responseText}</h1>
        </div>
        <br />
        <Link style={{ display: waiting ? "none" : "flex" }} to="/home">
          OK
        </Link>
      </div>
    </div>
  );
};

export default ActivateAcc;
