import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const checkMonth = (m) => {
  if (m === "00") {
    return "01";
  } else if (m === "01") {
    return "02";
  } else if (m === "02") {
    return "03";
  } else if (m === "03") {
    return "04";
  } else if (m === "04") {
    return "05";
  } else if (m === "05") {
    return "06";
  } else if (m === "06") {
    return "07";
  } else if (m === "07") {
    return "08";
  } else if (m === "08") {
    return "09";
  } else if (m === "09") {
    return "10";
  } else if (m === "10") {
    return "11";
  } else if (m === "11") {
    return "12";
  }
};

const LisenceAgreement = () => {
  //  Formatting the date (Today's date)
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth()).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  const date = `${dd}/${checkMonth(mm)}/${yyyy}`;

  const [term, setTerm] = useState("24");
  const [soldCopies, setSoldCopies] = useState("2500");
  const [performances, setPerformances] = useState("10");
  const [sharePerc, setSharePerc] = useState("50");

  const { leaseType } = useParams();
  console.log("leaseType", leaseType);

  useEffect(() => {
    if (leaseType === "premium") {
      setSoldCopies("5000");
      setTerm("48");
      setPerformances("20");
    } else if (leaseType === "unlimited") {
      setSoldCopies("5000");
      setTerm("unlimited");
      setPerformances("unlimited");
    } else if (leaseType === "exclusive") {
      setSoldCopies("5000");
      setTerm("unlimited");
      setPerformances("unlimited");
      setSharePerc("30");
    }
  }, [leaseType]);

  return (
    // Footer
    <div className="about">
      <div className="heading">
        <h1>Lisence Agreement</h1>
      </div>
      <div className="info">
        <h2>Intro:</h2>

        <p>
          {" "}
          This License agreement is made on {date} (“Effective Date”) by and
          between BUYER/CLIENT (“Licensee”) and Katwave (“Licensor”). Licensor
          warrants that it controls the mechanical rights in and to the musical
          work named "BEAT NAME" (“Composition”) being sold to Licensee as of
          and prior to the Effective Date. The Licensee and Licensor have agreed
          to the following terms:
        </p>

        <h2>Master Use</h2>
        <p>
          The Licensor hereby grants to Licensee a non-exclusive License to
          record vocal synchronization to the Composition partly or in its
          entirety and substantially in its original form (“Master Recording”).
        </p>

        <h2>Ownership</h2>
        <p>
          The Licensor maintains 100% full rights (copyright, publishing and
          ownership) of the Composition, and can continue to sell it
          non-exclusively and/or exclusively. The Licensee has neither the right
          nor authority to sell or license the rights to the Composition whether
          in whole or part to any other party. In the event another party
          purchases exclusive rights to the Composition from the Licensor, the
          Licensee will retain non-exclusive rights under the limitations listed
          in this agreement and until these terms have been fulfilled.
        </p>

        <h2>Mechanical Rights</h2>
        <p>
          The Licensor hereby grants to Licensee a non-exclusive License to use
          Master Recording in the reproduction, duplication, manufacture, and
          distribution of phonograph records, cassette tapes, compact disks,
          internet downloads, other and miscellaneous audio and digital
          recordings, and any lifts and versions thereof (collectively
          ,”Recordings”) worldwide for up to the pressing or selling a total of
          2500 copies of such Recordings or any combination of such Recordings.
          Additionally, Licensor shall be permitted to distribute {soldCopies}{" "}
          internet downloads for non-profit and non-commercial use.
        </p>

        <h2>Publishing and Royalties</h2>
        <p>
          The Licensee is entitled to keep{" "}
          {leaseType === "exclusive" ? "97%" : "100%"} of royalties and{" "}
          {sharePerc} (artist share) of publishing that is generated from sales
          of the Master Recording on digital retailers (such as iTunes), through
          physical sales (such as Compact Disks) and on online streaming
          services (such as Spotify) and synchronization licenses (works that
          are placed on television shows, film, advertisements, video games,
          movie trailers, etc.)
        </p>

        <h2>Performance Rights</h2>
        <p>
          The Licensor hereby grants to Licensee a non-exclusive License to use
          the Master Recording in {performances} non-profit performances, shows,
          or concerts. The Licensee is also permitted to use the Master
          Recording in 1 for-profit performance. The Licensee can earn up to one
          thousand ($1,000) US Dollars in compensation or ticket sales using the
          Master Recording in said performance
        </p>

        <h2>Music Videos</h2>
        <p>
          The Licensor hereby grants to Licensee a non-exclusive License to use
          the Master Recording in 1 music video.
        </p>

        <h2>Synchronization Rights</h2>
        <p>
          The Licensor hereby grants to Licensee a non-exclusive License to
          copy, perform, edit and/or loop portions of, record on film, video,
          digital animations, and video games (collectively, “Projects”) and use
          the Master Recording in synchronization or timed relation with the
          productions in one (1) Projects.
        </p>

        <h2>Broadcast Rights</h2>
        <p>
          The Licensor hereby grants to Licensee a non-exclusive license to
          broadcast or air the Master Recording on 1 radio station or through 1
          station channel, respectively.
        </p>

        <h2>Credit</h2>
        <p>
          Licensee shall acknowledge the original authorship of the Composition
          appropriately and reasonably in all media and performance formats by
          acknowledging the relevant author in writing where possible and
          vocally otherwise. Where a project is commercially released and
          registered with a performance rights organisation, Licensor shall be
          acknowledged as a Writer.
        </p>

        <h2>Sampling</h2>
        <p>
          The Licensee agrees that the Composition is purchased as a “Work Made
          for Hire” whereby the clearing of any sampled materials is the
          responsibility of Licensee.
        </p>

        <h2>Payment for this License is non-refundable.</h2>
        <p>
          If the Licensee fails to account to the Licensor, timely and complete
          the payments provided for hereunder, the Licensor shall have the right
          to terminate this License upon written notice to the Licensee. Such
          termination shall render the recording, manufacture and/or
          distribution of Recordings for which monies have not been paid subject
          to and actionable as infringements under applicable law.
        </p>

        <h2>Term</h2>
        <p>
          Executed by the Licensor and the Licensee, this License agreement is
          to be effective as for all purposes as of the Effective Date for a
          period of {term} months. In the event that an exclusive license is
          sold by Licensor to the Composition, the terms of this agreement shall
          be upheld.
        </p>

        <h2>Indemnification</h2>
        <p>
          Licensee agrees to indemnify and hold Licensor harmless from and
          against any and all claims, losses, damages, costs, and expenses,
          including, without limitation, reasonable attorneys’ fees, arising out
          of or resulting from a claimed breach of any of Licensee’s
          representations, warranties or agreements hereunder
        </p>

        <h2>Miscellaneous</h2>
        <p>
          This License is non-transferable and is limited to the Composition
          specified, constitutes the entire agreement between the Licensor and
          the Licensee relating to the Composition, and shall be binding upon
          both Licensor and Licensee and their respective successors, assigns,
          and legal representatives.
        </p>

        <h2>Governing Law</h2>
        <p>
          This License is governed by and shall be construed under the laws of
          the Licensor’s resident country, without regard to the conflicts of
          laws and principles thereof.
        </p>
      </div>
    </div>
  );
};

export default LisenceAgreement;
