import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const MiniPlayer = ({
  setAudioPlayed,
  audioPlayed,
  setPlayStr,
  audioSetup,
}) => {
  // Redux selectors
  const playing_song = useSelector((s) => s.playing_song);

  // history
  const history = useHistory();

  const path = useLocation().pathname;

  // Playing and pausing audio
  const PlayAndPause = () => {
    if (playing_song && playing_song.audio) {
      // const checkSrc = audioPlaylist[count].src.match(/undefined/i);
      audioSetup.playToggler({
        setPlaying: setAudioPlayed,
        playing: audioPlayed,
        str: setPlayStr,
      });
    }
  };

  // Go to the playing song
  const goToPlayingSong = () => {
    if (playing_song && playing_song._id) {
      history.push(`/playing/${playing_song._id}`);
    }
  };

  const renderPlayer = () => {
    return (
      <div
        className="mini-player"
        style={{
          display:
            path.match("/playing") || !playing_song._id ? "none" : "flex",
        }}
      >
        <div className="img">
          {playing_song && playing_song.cover && playing_song.cover.Location ? (
            <img src={playing_song.cover.Location} alt="cover" />
          ) : (
            <p>
              {" "}
              <i className="fas fa-headphones"></i>{" "}
            </p>
          )}
        </div>
        <div className="play-cont">
          <p onClick={PlayAndPause}>
            <i className={`fas fa-${audioPlayed ? "pause" : "play"}`}></i>
          </p>
        </div>
        <div className="details" onClick={goToPlayingSong}>
          <h1>{playing_song.name}</h1>
          <h2>{playing_song.artist}</h2>
        </div>
      </div>
    );
  };
  return <>{renderPlayer()}</>;
};
export default MiniPlayer;
