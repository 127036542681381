import { Route } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import Cart from "../pages/Cart";
import PaymentCancel from "../pages/PaymentCancel";
import PaymentSuccess from "../pages/PaymentSuccess";

const Buyer = [
  {
    routeType: ProtectedRoute,
    path: "/cart",
    exact: true,
    component: Cart,
  },
  {
    routeType: Route,
    path: "/payment/success",
    exact: true,
    component: PaymentSuccess,
  },
  {
    routeType: Route,
    path: "/payment/cancel",
    exact: true,
    component: PaymentCancel,
  },
];

export default Buyer;
