import UploadSong from "../pages/UploadSong";
import ProtectedRoute from "../components/ProtectedRoute";
import AdminSongs from "../pages/AdminSongs";
import SongCover from "../pages/SongCover";
import AdminOrders from "../pages/AdminOrders";
import Bookings from "../pages/Bookings";

const Admin = [
  {
    routeType: ProtectedRoute,
    path: "/admin/songs",
    exact: true,
    component: AdminSongs,
  },
  {
    routeType: ProtectedRoute,
    path: "/admin/upload",
    exact: true,
    component: UploadSong,
  },
  {
    routeType: ProtectedRoute,
    path: "/admin/upload/:id",
    exact: true,
    component: SongCover,
  },
  {
    routeType: ProtectedRoute,
    path: "/admin/orders",
    exact: true,
    component: AdminOrders,
  },
  {
    routeType: ProtectedRoute,
    path: "/admin/bookings",
    exact: true,
    component: Bookings,
  },
];

export default Admin;
