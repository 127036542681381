import images from "../../abstracts/Images";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Functions from "../../abstracts/Functions";
import { useState } from "react";
import SongActions from "../../redux/actions/SongActions";
import FLASH from "../../redux/actions/Flash";
import TimeAgo from "react-timeago";

const Comments = ({ displayComments, toggleDisplay, setDisplay }) => {
  //  Redux selectors
  const playing_song = useSelector((s) => s.playing_song);
  const user = useSelector((s) => s.user);

  // React states
  const [comment, setComment] = useState("");

  // Dispatch
  const dispatch = useDispatch();

  // When typing comment
  const typing = (e) => {
    setComment(e.target.value);
  };

  // Send a comment on click
  const sendComment = (e) => {
    e.preventDefault();

    // Empty comment string
    setComment("");

    if (comment) {
      if (user._id) {
        axios({
          url: `${Functions.getAPI()}/song/comment/${playing_song._id}`,
          method: "POST",
          withCredentials: true,
          data: { comment },
        })
          .then(async (res) => {
            const data = await res.data;

            dispatch(
              SongActions.COMMENT({ id: data.id, comment: data.comment })
            );
          })
          .catch((e) => {
            const res = e.response;
            if (res) {
              console.log(res.data);
            } else {
              console.log(e);
            }
          });
      } else {
        // Show popup message
        setDisplay(true);
        dispatch(
          FLASH({ err: "", warn: "You are not logged in!", success: "" })
        );
      }
    }
  };

  // Render all comments
  const renderComments = () => {
    if (playing_song._id) {
      if (playing_song.comments.length > 0) {
        return playing_song.comments.map((c, ind) => (
          <div
            key={ind}
            className={`comment ${c.sender === user._id ? "me" : "them"}`}
          >
            <div className="prof-img">
              <img src={images.avatar} alt="profile pic" />
            </div>
            <div className="comment-box">
              <h3>
                <Link onClick={(e) => e.preventDefault()} to="/">
                  {c.username}
                </Link>
              </h3>
              <p>{c.text}</p>
            </div>
            <div className="dt">
              {/* <p><TimeAgo date={comm.dateAdded} /></p> */}
              <p>
                <TimeAgo date={c.dateAdded} />{" "}
              </p>
            </div>
          </div>
        ));
      } else {
        return <div style={{ color: "#777" }}>No comments yet!</div>;
      }
    }
  };

  return (
    <div
      className="comments-container"
      style={displayComments ? { display: "flex" } : { display: "none" }}
    >
      <div className="comm-nav">
        <button className="close-comments" onClick={toggleDisplay}>
          X
        </button>
      </div>
      <div className="comments-comp">{renderComments()}</div>

      <div className="write-comment">
        <form action="#">
          <textarea
            name="comment"
            id="comment"
            placeholder="Type comment..."
            value={comment}
            onChange={typing}
            onKeyUp={typing}
          ></textarea>
          <button type="submit" onClick={sendComment}>
            <i className="fas fa-paper-plane" aria-hidden="true"></i>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Comments;
