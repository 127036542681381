import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <div className="services-container">
      <div id="pricing" className="pricing-container">
        <div className="heading">
          <h1>Pricing</h1>
          <span></span>
        </div>

        <div className="pricing-content">
          <div className="card">
            <h2>Basic Lease</h2>
            <p className="price">R99</p>
            <ul>
              <li>24 months</li>
              <li>Mp3 file</li>
              <li>Not allowed to resell</li>
              <li>
                Distribution only to Spotify, iTunes and Non-paying online
                platforms. 50% on sales
              </li>
              <li>Allowed to play on Radio but not TV</li>
              <li>No ownership</li>
            </ul>
            <Link to="/lease/basic">Read more</Link>
          </div>
          <div className="card">
            <h2>Premium Lease</h2>
            <p className="price">R619</p>
            <ul>
              <li>48 months</li>
              <li>Mp3 file, WAV file and Stems</li>
              <li>Not allowed to resell</li>
              <li>
                Distribution to all paying online platforms and 50% on sales
              </li>
              <li>Allowed to play on Radio and TV</li>
              <li>No ownership</li>
            </ul>
            <Link to="/lease/premium">Read more</Link>
          </div>
          <div className="card">
            <h2>Unlimited Lease</h2>
            <p className="price">R1199</p>
            <ul>
              <li>Unlimited commercial use</li>
              <li>Mp3 file, WAV file, Stems and FLP</li>
              <li>Not allowed to resell</li>
              <li>
                Distribution to all paying online platforms and 50% on sales
              </li>
              <li>Allowed to play on Radio and TV</li>
              <li>Unrestricted rights</li>
              <li>No ownership</li>
            </ul>
            <Link to="/lease/unlimited">Read more</Link>
          </div>
          <div className="card">
            <h2>Exclusive rights</h2>
            <p className="price">R1999</p>
            <ul>
              <li>Unlimited commercial use</li>
              <li>Mp3 file, WAV file, Stems and FLP</li>
              <li>Allowed to resell</li>
              <li>
                Distribution to all paying online platforms and 30% on sales
              </li>
              <li>Allowed to play on Radio and TV</li>
              <li>Unrestricted rights</li>
              <li>Full ownership</li>
            </ul>
            <Link to="/lease/exclusive">Read more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
