class AudioSetup {
  constructor(audio, playlist, count = 0) {
    // The actual audio elemet. From (new Audio())
    this.audio = audio;

    // Playlist
    this.playlist = playlist;

    // Count to use for clicking back or next
    this.count = count;
  }

  // Play and pause
  playToggler({ playing, setPlaying, str }) {
    if (this.audio.src) {
      setPlaying(!playing);
      if (playing) {
        this.audio.pause();
        str("play");
      } else {
        this.audio.play();
        str("pause");
      }
    }
  }

  // Forward and backgward
  forward({ setPlaying, audList, setCount, str }) {
    str("pause");
    setPlaying(true);

    if (this.count >= audList.length - 1) {
      setCount(0);
      this.audio.pause();
      this.audio.currentTime = 0;
      audList[0].currentTime = 0;
      audList[0].play();
    } else {
      setCount(this.count + 1);
      this.audio.pause();
      this.audio.currentTime = 0;
      audList[this.count + 1].currentTime = 0;
      audList[this.count + 1].play();
    }
  }

  backward({ setPlaying, audList, setCount, str }) {
    str("pause");
    setPlaying(true);

    if (this.count <= 0) {
      setCount(audList.length - 1);
      this.audio.pause();
      this.audio.currentTime = 0;
      audList[audList.length - 1].currentTime = 0;
      audList[audList.length - 1].play();
    } else {
      setCount(this.count - 1);
      this.audio.pause();
      this.audio.currentTime = 0;
      audList[this.count - 1].currentTime = 0;
      audList[this.count - 1].play();
    }
  }

  // Looping
  loopToggler({ ref, onColor, offColor }) {
    if (this.audio.src) {
      if (this.audio.loop) {
        this.audio.loop = false;
        ref.style.color = offColor;
      } else if (!this.audio.loop) {
        this.audio.loop = true;
        ref.style.color = onColor;
      }
    }
  }

  // Muting and unmuting
  mutetoggler({ setMuted, muted, str }) {
    if (this.audio.src) {
      setMuted(!muted);
      this.audio.muted = muted;
      muted ? str("volume-down") : str("volume-up");
    }
  }

  // Change volume value
  volumeChanger(val) {
    if (this.audio.src) {
      this.audio.volume = val / 100;
    }
  }

  // audio seeker
  audioSeeker(val) {
    if (this.audio.src) {
      const seekVal = this.audio.duration * (val / 100);
      this.audio.currentTime = seekVal;
    }
  }

  // Update the audio time
  updateTime({ currentTime, seekVal }) {
    const currentSeekVal = this.audio.currentTime * (100 / this.audio.duration);

    // updating seek value when current time changes
    seekVal(currentSeekVal);

    // Showing current time and duration for the currentaudio
    currentTime(this.funcs().getTime(this.audio.currentTime));
  }

  // Global functions
  funcs() {
    return {
      getTime: (thing) => {
        const time = Math.floor(thing);
        const min = Math.floor(time / 60);
        const sec = (time % 60).toFixed(0);
        return min + ":" + (sec < 10 ? "0" : "") + sec;
      },
    };
  }
}

export default AudioSetup;
