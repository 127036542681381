const SongForm = ({ nextBack, inputNames, vals, onChange, refs }) => {
  return (
    <form className="form-slide" method="POST">
      <input
        type="text"
        className="formInput"
        name={inputNames[0]}
        ref={refs[0]}
        placeholder={`Enter song name`}
        onChange={onChange}
        value={vals[0]}
      />

      <input
        type="text"
        className="formInput"
        name={inputNames[1]}
        ref={refs[1]}
        placeholder={`Enter ${inputNames[1]}`}
        onChange={onChange}
        value={vals[1]}
      />

      <input
        type="text"
        className="formInput"
        name={inputNames[2]}
        ref={refs[2]}
        placeholder={`Enter ${inputNames[2]}`}
        onChange={onChange}
        value={vals[2]}
      />
      <p style={{ color: "#bbb" }}>
        Enter <strong style={{ color: "#777" }}>none</strong> if no featured
      </p>

      <input
        type="text"
        className="formInput"
        name={inputNames[3]}
        ref={refs[3]}
        placeholder={`Enter ${inputNames[3]}`}
        onChange={onChange}
        value={vals[3]}
      />

      <input
        type="text"
        className="formInput"
        name={inputNames[4]}
        ref={refs[4]}
        placeholder={`Enter ${inputNames[4]}`}
        onChange={onChange}
        value={vals[4]}
      />

      <input
        type="text"
        className="formInput"
        name={inputNames[5]}
        ref={refs[5]}
        placeholder={`Enter ${inputNames[5]}`}
        onChange={onChange}
        value={vals[5]}
      />

      <select
        name={inputNames[6]}
        value={vals[6]}
        onChange={onChange}
        ref={refs[6]}
      >
        <option value="">Select genre:</option>
        <option value="hip-hop">hip-hop</option>
        <option value="house">house</option>
        <option value="jazz">jazz</option>
        <option value="country">country</option>
        <option value="rock">rock</option>
        <option value="pop">pop</option>
        <option value="rnb">rnb</option>
        <option value="metal">metal</option>
        <option value="blues">blues</option>
        <option value="reggae">reggae</option>
        <option value="techno">techno</option>
        <option value="soul">soul</option>
        <option value="edm">edm</option>
      </select>

      <select
        name={inputNames[7]}
        value={vals[7]}
        onChange={onChange}
        ref={refs[7]}
      >
        <option value="">Select type:</option>
        <option value="beat">beat</option>
        <option value="track">track</option>
      </select>
      <br />

      <button onClick={nextBack} type="submit" className="submit-button">
        Next
      </button>
      <br />
    </form>
  );
};
export default SongForm;
