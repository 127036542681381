import Functions from "../abstracts/Functions";

const About = () => {
  const ulStyles = {
    marginLeft: "20px",
  };

  Functions.pageInfo({
    title: "About Katwave",
    desc: "About Katwave Platform",
  });

  return (
    <div className="about">
      <div className="heading">
        <h1>About Katwave</h1>
      </div>

      <div className="info">
        <p>
          I am a producer, rapper, singer and songwriter. My main goal is to
          make it easy for artists to buy beats online and put more time working
          on their next hit song.
        </p>
        <br />
        <p>Don't have an account yet?</p>
        <a href="/register" className="btn">
          Get started
        </a>

        <h2>Contact information</h2>
        <p>
          Lebowakgomo Zone F (0737) Polokwane, Limpopo (South Africa)
          <br />
        </p>
        <p>
          <span>Phone:</span> 0640009442
        </p>
        <p>
          <span>Email:</span> support@katwave.co.za
        </p>
        <br />
        <p>
          <span>Name:</span> Katlego Mangoale
        </p>

        <h2>Disclaimer</h2>
        <p>This website may contain links to external website.</p>
        <p>
          <strong>
            Report infringement of copyright to{" "}
            <a href="mailto:support@katwave.co.za">support@katwave.co.za</a>
          </strong>
        </p>

        <h2>Our communities</h2>
        <p>To connect with use, follow us on:</p>
        <ul style={{ marginLeft: "20px" }}>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.facebook.com/katwavebeats"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.instagram.com/katlego_man"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.youtube.com/channel/UCHBQDb7wynQmWSQzYUC_3Hw"
            >
              Youtube
            </a>
          </li>
        </ul>

        <h2 id="privacy-policy">Privacy policy</h2>
        <p>
          <span> Purpose of this policy </span>
        </p>
        <p>
          We respect your privacy and take the protection of personal
          information very seriously. The purpose of this policy is to describe
          the way we collect, store, use and protect information that can be
          associated with a specific natural or juristic person and can be used
          to identify that person (“personal information”).
        </p>
        <br />
        <p>
          <span> Your personal information </span>
        </p>
        <p>
          Your personal information is stored in our database to allow you to
          login and use our web app. The user's personal information including
          certain information collected during registration, optional
          information that you voluntarily provide to us or any other
          information will not be shared for no reason at any time. We will only
          share your E-mail, Phone number, your Firstname and Lastname when you
          order so that you can be contacted by sellers to come and collect
          ordered item(s) or for confirmation.
        </p>
        <br />
        <p>
          <span> Changes </span>
        </p>
        <p>
          We may change the terms of this policy at any time. We will notify you
          of any changes by placing a notice in a prominent place on the website
          or by email. If you do not agree with the change you must stop using
          the services. If you continue to use the services following
          notification of a change to the terms, the changed terms will apply to
          you and you will be deemed to have accepted such terms.
        </p>
        <br />
        <p>
          <span> Collection </span>
        </p>
        <p>
          On registration. Once you register on our website, you will no longer
          be anonymous to us as you will provide us with personal information.
          This personal information will include your Firstname, Lastname, Phone
          number, E-mail and Password.
        </p>
        <br />
        <p>
          <span> Local storage </span>
        </p>
        <p>
          We strore your e-mail in local storage in order to make it easy for
          you to login to our website.
        </p>
        <br />
        <p>
          <span> Update password </span>
        </p>
        <p>
          If you forgot your password in our website you can simply click the
          "Forgot password" link below the login button on the login page.
        </p>
        <br />
        <p>
          <span> Limitation </span>
        </p>
        <p>
          We are not responsible for, give no warranties, nor make any
          representations in respect of the privacy policies or practices of any
          third party websites.
        </p>
        <br />
        <p>
          <span> Enquiries </span>
        </p>
        <p>
          If you have any questions or concerns arising from this privacy policy
          or the way in which we handle personal information, please contact us
          at: <br />
          <a href="mailto:support@katwave.co.za">support@katwave.co.za</a>
        </p>

        <h2>Terms and Conditions</h2>
        <p className="title">Introduction:</p>
        <ol style={ulStyles}>
          <li>
            This website can be accessed at www.Katwave.co.za, and is owned and
            operated Katlego Mangoale.
          </li>
          <li>
            These Website Terms and Conditions (“Terms and Conditions”) govern
            the use of the website.
          </li>
          <li>
            These Terms and Conditions are binding and enforceable against every
            person that accesses or uses this Website. By using the Website and
            by registering on the Website, as may be applicable, you acknowledge
            that you have read and agree to be bound by these Terms and
            Conditions.
          </li>
        </ol>

        <p className="title">Important notice:</p>
        <ol style={ulStyles}>
          <li>
            These Terms and Conditions also apply to users who are consumers for
            purposes of the Consumer Protection Act, 68 of 2008 (the “CPA”).
          </li>
          <li>
            Your attention is drawn to these Terms and Conditions because they
            are important and should be carefully noted.
          </li>
          <li>
            Nothing in these Terms and Conditions is intended or must be
            understood to unlawfully restrict, limit or avoid any right or
            obligation, as the case may be, created for either you or Katwave in
            terms of the CPA.
          </li>
        </ol>

        <p className="title">Registration and use of the website:</p>
        <ol style={ulStyles}>
          <li>
            Only registered users may download and comment on tracks/beats.
            Also, they are eligible to buy products on the platform.
          </li>
          <li>
            To register as a user, you must provide a unique email address and
            password and provide certain personal details to Katwave. You will
            need to use your unique email and password to access the Website.
          </li>
          <li>
            You agree and warrant that your email and password shall:
            <ul style={ulStyles}>
              <li>be used for personal use only; and</li>
              <li>not be disclosed by you to any third party.</li>
            </ul>
          </li>
          <li>
            For security purposes you agree to enter the correct email and
            password whenever logging in to our website, failing which you will
            be denied access.
          </li>
          <li>
            You agree that, once the correct username and password relating to
            your account have been entered, irrespective of whether the use of
            the username and password is unauthorised or fraudulent, you will be
            liable for any action made.
          </li>
          <li>
            You agree that you are atleat 13 years or older when using the
            website.
          </li>
        </ol>
      </div>
    </div>
  );
};
export default About;
