import { Route } from "react-router";
// import Offline from "../static/Offline";
import About from "../pages/About";
import Contact from "../pages/Contact";
// import Faq from "../static/Faq";
// import ActivateAcc from "../static/ActivateAcc";
// import Success from "../static/Success";
// import AppSuccess from "../static/AppSuccess";
import AppCrash from "../components/AppCrash";
import Book from "../pages/Book";
import LisenceAgreement from "../pages/LisenceAgreement";

const Static = [
  // {
  //   routeType: Route,
  //   path: "/offline",
  //   exact: true,
  //   component: Offline,
  // },
  {
    routeType: Route,
    path: "/book",
    exact: true,
    component: Book,
  },
  {
    routeType: Route,
    path: "/about",
    exact: true,
    component: About,
  },
  {
    routeType: Route,
    path: "/contact",
    exact: true,
    component: Contact,
  },
  {
    routeType: Route,
    path: "/error",
    exact: true,
    component: AppCrash,
  },
  {
    routeType: Route,
    path: "/lease/:leaseType",
    exact: true,
    component: LisenceAgreement,
  },
  // {
  //   routeType: Route,
  //   path: "/help",
  //   exact: true,
  //   component: Faq,
  // },
  // {
  //   routeType: Route,
  //   path: "/success",
  //   exact: true,
  //   component: Success,
  // },
  // {
  //   routeType: Route,
  //   path: "/apply/success",
  //   exact: true,
  //   component: AppSuccess,
  // },
];

export default Static;
