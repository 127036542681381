import { useEffect, useRef, useState } from "react";
import { times } from "./Audioly";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BuyerActions from "../redux/actions/BuyerActions";
import FLASH, { RESTORE_FLASH } from "../redux/actions/Flash";
import Functions from "../abstracts/Functions";

const SongCard = ({
  type,
  audioFile,
  name,
  artist,
  plays,
  _id,
  img,
  setDisplay,
  downloads,
  admin,
}) => {
  // Redux Selectors
  const user = useSelector((state) => state.user);
  const userID = user._id;
  const shoppingCart = useSelector((state) => state.cart);
  const cart = "items" in shoppingCart ? shoppingCart : { items: [], total: 0 };
  const playing_song = useSelector((s) => s.playing_song);

  // Use states
  const [duration, setDuration] = useState("00:00");

  // Dispatch
  const dispatch = useDispatch();

  // Refs
  const audio = useRef();

  useEffect(() => {
    audio.current = new Audio(audioFile);

    //   Showing current time and duration for the current audio after page loads
    // And also updating the UI
    audio.current.onloadedmetadata = () => {
      setDuration(times(audio.current).duration);
    };
  }, [audioFile]);

  const addToCart = (e) => {
    e.preventDefault();

    // Restoring the flash messages
    dispatch(RESTORE_FLASH());

    if (userID) {
      axios({
        url: `${Functions.getAPI()}/cart`,
        method: "POST",
        withCredentials: true,
        data: { _id },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Showing the popup message
          setDisplay(true);

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
          const price = parseInt(data.price);
          console.log("data", data.data);
          dispatch(
            BuyerActions.ADD_TO_CART({
              items: [...cart.items, data],
              total: cart.total + price,
            })
          );
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            // Showing the popup message
            setDisplay(true);

            console.log("res data:", res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });
    } else {
      // Showing the popup message
      setDisplay(true);
      dispatch(FLASH({ err: "", success: "", warn: "You are not logged in!" }));
    }
  };

  return (
    <>
      <a
        href={`/playing/${_id}`}
        className="song-card"
        style={
          playing_song._id && playing_song._id === _id
            ? { background: "rgb(51, 13, 20)" }
            : { background: "transparent" }
        }
      >
        <div className="art">
          {img ? (
            <img src={img} alt="cover" />
          ) : (
            <p>
              {" "}
              <i className="fas fa-headphones"></i>
            </p>
          )}
        </div>
        <div className="details">
          <h1>{name}</h1>
          <h2>{artist}</h2>
          <div className="feedback" style={{ display: "flex" }}>
            <p>
              <i className="fas fa-play"></i> <div className="dot"></div>{" "}
              {Functions.numFormatter(plays)}
            </p>
            {type === "track" && (
              <p className="downloads">
                <i className="fas fa-cloud-download"></i>{" "}
                <div className="dot"></div> {Functions.numFormatter(downloads)}
              </p>
            )}
          </div>
        </div>
        <div className="dur">
          <p>{duration}</p>
        </div>
        {type === "beat" && !admin && (
          <button className="add" onClick={addToCart}>
            +<i className="fas fa-shopping-cart"></i>
          </button>
        )}
      </a>
    </>
  );
};

export default SongCard;
