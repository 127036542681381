import images from "../abstracts/Images";
import { Link } from "react-router-dom";
import Functions from "../abstracts/Functions";

const Error404 = () => {
  Functions.pageInfo({
    title: "Katwave | Error 404!",
    desc: "Error 404!",
  });

  return (
    <div className="error">
      <div className="error-container">
        <h2 style={{ color: "#fff" }}>404</h2>
        <br />
        <div className="img">
          <img src={images.illustrator} alt="illustration" />
        </div>
        <p>Resource not found!</p>
        <br />
        {/* If a user is logged in */}
        <Link style={{ textAlign: "center" }} to="/">
          Go back home
        </Link>
      </div>
    </div>
  );
};

export default Error404;
