const Functions = {
  msToMinSec: (m) => {
    var min = Math.floor(m / 60000);
    var sec = ((m % 60000) / 1000).toFixed(0);
    return min + ":" + (sec < 10 ? "0" : "") + sec;
  },
  percentage: (n) => {
    var perc = Math.floor(n);
    return perc;
  },
  numFormatter: (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000 && num < 999999) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num > 1000000000) {
      return (num / 1000000000).toFixed(1) + "B"; // convert to B for number from > 1 billion
    } else if (num < 999) {
      return num; // if value < 1000, nothing to do
    }
  },
  limitText: (textToLimit, limit) => {
    let finalText = "",
      text2 = textToLimit.replace(/\s+/g, " "),
      text3 = text2.split(""),
      numOfWords = text3.length,
      i = 0;

    if (numOfWords > limit) {
      for (i; i < limit; i++) finalText = finalText + "" + text3[i] + "";
      return finalText + "...";
    } else {
      return textToLimit;
    }
  },
  uniqueArr: (val, ind, arr) => {
    return arr.indexOf(val) === ind;
  },
  formatDate: (date) => {
    const spl = date.split("");
    let y, m, d;
    y = spl[0] + spl[1] + spl[2] + spl[3];
    m = spl[4] + spl[5];
    d = spl[6] + spl[7];

    return `${y}/${m}/${d}`;
  },
  pageInfo: (data = {}) => {
    data.title = data.title || "Default title";
    data.desc = data.desc || "Default description";

    document.title = data.title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", data.desc);
  },
  getAPI: () => {
    if (process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_DEV_API_URL;
    } else if (process.env.NODE_ENV === "production") {
      return process.env.REACT_APP_PROD_API_URL;
    }
  },
  sendNotif: (
    payload = { sub: null, _id: null, body: null, url: null, fetchUrl: null }
  ) => {
    const data = {
      subscription: payload.sub,
      _id: payload._id,
      body: payload.body,
      url: payload.url,
    };

    // Post the data to server
    fetch(payload.fetchUrl, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => console.log("Push success..."))
      .catch((err) => console.log("err: ", err));
  },
  postDate: (date) => {
    const dateInst = new Date();
    const yest = dateInst.getDate() - 1;
    const tod = dateInst.getDate();
    const week = 7;
    const m = String(dateInst.getMonth() + 1).padStart(2, "0");
    const y = dateInst.getFullYear();

    const splitDate = date.split("/");
    if (splitDate[1] === m && splitDate[2] === String(y)) {
      if (splitDate[0] === String(tod)) {
        return "Today";
      } else if (splitDate[0] === String(yest)) {
        return "Yesterday";
      } else if (splitDate[0] - 2) {
        return "2 days ago";
      } else if (splitDate[0] - 3) {
        return "3 days ago";
      } else if (splitDate[0] - 4) {
        return "4 days ago";
      } else if (splitDate[0] - 5) {
        return "5 days ago";
      } else if (splitDate[0] - 6) {
        return "6 days ago";
      } else if (splitDate[0] - week) {
        return "1 week ago";
      } else if (splitDate[0] - week * 2) {
        return "2 weeks ago";
      } else if (splitDate[0] - week * 3) {
        return "3 weeks ago";
      } else if (splitDate[0] - week * 4) {
        return "4 weeks ago";
      } else if (splitDate[0] - week * 5) {
        return "5 weeks ago";
      } else {
        return date;
      }
    } else {
      return date;
    }
  },
};

export default Functions;
