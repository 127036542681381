import images from "../abstracts/Images";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DesktopNavLinks from "./DesktopNavLinks";
import { useState } from "react";

const DesktopNav = (props) => {
  // Accessing state
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);

  // States
  const [displayLinks, setDisplayLinks] = useState(false);
  const [link, setLink] = useState("");

  // DISPLAY TOGGLE
  const toggleNavLinks = (e) => {
    e.preventDefault();
    setDisplayLinks(!displayLinks);
  };

  // DISPLAY NAV LINKS
  const displayDesktopLinks = (e, link) => {
    e.preventDefault();
    toggleNavLinks(e);
    setLink(link);
  };

  return (
    // Desktop Navigation
    <>
      <div className="desktop-nav landing">
        <div className="logo">
          <Link to="/">
            <img src={images.logos.logo512} alt="logo" />
            {/* <span className="app-name">atwave</span> */}
          </Link>
        </div>
        <div className="links">
          <ul className="desktop-list">
            <li>
              <Link
                to="/music"
                onClick={(e) => {
                  displayDesktopLinks(e, "music");
                }}
              >
                Music <i className="fas fa-chevron-down"></i>
              </Link>
            </li>
            {user.admin && (
              <li>
                <Link to="/admin/bookings">Bookings</Link>
              </li>
            )}
            {!user.admin && (
              <li>
                <Link to="/about">About</Link>
              </li>
            )}
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            {!user._id && (
              <li>
                <Link to="/login">Login</Link>
              </li>
            )}

            {user._id && user.admin && (
              <li>
                <Link to="/admin/songs">Admin</Link>
              </li>
            )}

            {user._id && (
              <li>
                <Link
                  to="/account"
                  onClick={(e) => {
                    displayDesktopLinks(e, "acc");
                  }}
                >
                  <i className="fas fa-user" style={{ marginRight: "6px" }}></i>{" "}
                  {user.firstName}
                </Link>
              </li>
            )}

            <li className="open-search">
              <Link to="/login" onClick={props.toggleDesktopSearch}>
                {" "}
                <i className="fa fa-search" aria-hidden="true">
                  {" "}
                </i>
              </Link>
            </li>

            <li>
              <Link to="/cart" className="login-navLink">
                <i className="fas fa-shopping-bag"></i>
                <span className="cart-items-num">{cart.items.length}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Search box */}
      <div
        className="search-box"
        style={{ display: props.showSearch ? "flex" : "none" }}
      >
        <div className="close-search" onClick={props.toggleDesktopSearch}>
          <p>x</p>
        </div>
        <form action="/search">
          <input
            onChange={props.onChange}
            value={props.q}
            type="text"
            placeholder="Search song name or artist"
          />
        </form>
        <div className="search-go" onClick={props.searchSongs}>
          <p>
            <i className="fa fa-arrow-right" aria-hidden="true">
              {" "}
            </i>
          </p>
        </div>
      </div>

      {/* Desktop Nav Links */}
      <DesktopNavLinks
        link={link}
        displayLinks={displayLinks}
        toggleNavLinks={toggleNavLinks}
      />
    </>
  );
};

export default DesktopNav;
