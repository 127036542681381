import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RESTORE_FLASH } from "../redux/actions/Flash";
import Functions from "../abstracts/Functions";
import images from "../abstracts/Images";
import { Link } from "react-router-dom";

const PaymentCancel = () => {
  Functions.pageInfo({
    title: "Katwave | Payment cancelled",
    desc: "Payment cancel page",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  return (
    <div className="register-login">
      <div className="form">
        <form className="form-slide">
          <img src={images.cross} alt="check badge" />
        </form>
        <div className="heading">
          <h1 style={{ marginTop: "10px" }}>Payment cancelled</h1>
        </div>
        <Link to="/">OK</Link>
      </div>
    </div>
  );
};

export default PaymentCancel;
