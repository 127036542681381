const BookingCard = ({ booking, deleteBooking }) => {
  return (
    <div className="table">
      <form
        className="main-form"
        action="/employee/update-booking"
        method="POST"
      >
        <div className="heading">
          <h2>{booking.name}</h2>

          {/* Email */}
          <div className="content">
            <div>
              <p>
                <strong>Email: </strong>
                {booking.email}
              </p>
            </div>
          </div>

          {/* Phone */}
          <div className="content">
            <div>
              <p>
                <strong>Phone: </strong>
                {booking.phone}
              </p>
            </div>
          </div>

          {/* Date */}
          <div className="content">
            <div>
              <p>
                <strong>Date: </strong>
                {booking.date}
              </p>
            </div>
          </div>

          {/* Company */}
          <div className="content">
            <div>
              <p>
                <strong>Company: </strong>
                {booking.company}
              </p>
            </div>
          </div>

          {/* Location */}
          <div className="content">
            <div>
              <p>
                <strong>Location: </strong>
                {booking.location}
              </p>
            </div>
          </div>

          {/* Venue */}
          <div className="content">
            <div>
              <p>
                <strong>Venue: </strong>
                {booking.venue}
              </p>
            </div>
          </div>

          {/* EventType */}
          <div className="content">
            <div>
              <p>
                <strong>EventType: </strong>
                {booking.eventType}
              </p>
            </div>
          </div>

          {/* Volume */}
          <div className="content">
            <div>
              <p>
                <strong>Volume: </strong>
                {booking.volume}
              </p>
            </div>
          </div>

          {/* StartingTime */}
          <div className="content">
            <div>
              <p>
                <strong>StartingTime: </strong>
                {booking.startingTime}
              </p>
            </div>
          </div>

          {/* Atmosphere */}
          <div className="content">
            <div>
              <p>
                <strong>Atmosphere: </strong>
                {booking.atmosphere}
              </p>
            </div>
          </div>
        </div>
      </form>

      {/* Delete button */}
      <form
        className="delete-form"
        action="/employee/delete-booking"
        method="POST"
      >
        <button
          className="delete"
          type="submit"
          onClick={(e) => {
            deleteBooking(e, booking._id);
          }}
        >
          Delete
        </button>
      </form>
    </div>
  );
};

export default BookingCard;
