import SongCard from "../assets/SongCard";
import Comments from "../components/player/Comments";
import ArtControls from "../components/player/ArtControls";
import MiniControls from "../components/player/MiniControls";
import SongDetails from "../components/player/SongDetails";
import SongActions from "../redux/actions/SongActions";
import { times } from "../assets/Audioly";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Functions from "../abstracts/Functions";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../assets/Loader";
import Popup from "../assets/Popup";

const Player = ({
  audioSetup,
  setAudioPlayed,
  audioPlayed,
  setPlayStr,
  audioPlaylist,
  setAudioPlaylist,
  count,
  setCount,
}) => {
  // Use states
  const [muteStr, setMuteStr] = useState("volume-up");
  const [muted, setMuted] = useState("volume-up");
  const [seekVal, setSeekVal] = useState(0);
  const [volumeVal, setVolumeVal] = useState(100);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");
  const [displayComments, setDisplayComments] = useState(false);
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loopRef, setLoopRef] = useState(null);

  // Use selector
  const playlist = useSelector((s) => s.songs);
  const flash = useSelector((s) => s.flash);
  const playing_song = useSelector((s) => s.playing_song);

  // Page information
  Functions.pageInfo({
    title: `Playing song | ${playing_song.name}`,
    desc: "Playing a song",
  });

  // Redux
  const dispatch = useDispatch();

  // History
  const history = useHistory();

  // Location info
  const { _id } = useParams();

  // Toggles
  const toggleCommentsDisplay = (e) => {
    e.preventDefault();
    setDisplayComments(!displayComments);
  };

  // Audio stuff

  // Time update
  const setTimeUpdate = () => {
    audioSetup.playlist[count].ontimeupdate = () => {
      audioSetup.updateTime({
        currentTime: setCurrentTime,
        seekVal: setSeekVal,
      });

      if (audioSetup.playlist[count].ended) {
        audioSetup.forward({
          setPlaying: setAudioPlayed,
          audList: audioSetup.playlist,
          setCount,
          str: setPlayStr,
        });
      }
    };
  };

  // Setting the audio source for the audio
  // html element, and getting the current time
  // and duration when the audio is loaded
  useEffect(() => {
    if (audioPlaylist.length >= 1) {
      //   Showing current time and duration for the current audio after page loads
      audioSetup.audio.onloadedmetadata = () => {
        setCurrentTime(times(audioSetup.audio).currentTime);
        setDuration(times(audioSetup.audio).duration);
      };

      setCurrentTime(times(audioSetup.audio).currentTime);
      setDuration(times(audioSetup.audio).duration);

      // Remove the loader
      setLoading(false);

      // Update the audio time
      setTimeUpdate();
    }
  }, [audioPlaylist, audioSetup.audio, count]);

  // Sending a reques to server to increment number of plays
  const requestPlay = () => {
    setPlayStr("play");
    const play = (val) => {
      axios({
        url: `${Functions.getAPI()}/song/play/${_id}`,
        method: "POST",
        withCredentials: true,
      })
        .then(async (res) => {
          const data = await res.data;

          if (!val) {
            dispatch(SongActions.PLAYING_SONG(data.playlist[count]));
            const audios = data.playlist.map((i) => {
              return new Audio(`${i.audio.Location}`);
            });
            dispatch(SongActions.SONGS(data.playlist));
            setAudioPlaylist(audios);
          } else {
            dispatch(SongActions.PLAYING_SONG(playlist[count]));
          }
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            console.log(res.data);
          } else {
            console.log(e);
          }
        });
    };
    if (audioPlaylist.length === 0) {
      play(false);
    } else {
      play(true);
    }
  };

  // Adding a play everytime a user plays a song
  useEffect(() => {
    requestPlay();
  }, [count]);

  // Playing and pausing audio
  const PlayAndPause = () => {
    if (audioSetup.playlist.length >= 1) {
      audioSetup.playToggler({
        setPlaying: setAudioPlayed,
        playing: audioPlayed,
        str: setPlayStr,
      });
    }
  };

  // Forward
  const goForward = () => {
    if (audioSetup.playlist.length >= 1) {
      history.push(`/playing/${playlist[audioSetup.count]._id}`);
      audioSetup.forward({
        setPlaying: setAudioPlayed,
        audList: audioSetup.playlist,
        setCount,
        str: setPlayStr,
      });
    }
  };
  // Back
  const goBackward = () => {
    if (audioPlaylist.length >= 1) {
      history.push(`/playing/${playlist[audioSetup.count]._id}`);
      audioSetup.backward({
        setPlaying: setAudioPlayed,
        audList: audioPlaylist,
        setCount,
        str: setPlayStr,
      });
    }
  };

  // Mute and unmute
  const muteUnmute = () => {
    if (audioPlaylist.length >= 1) {
      audioSetup.mutetoggler({
        setMuted: setMuted,
        muted: muted,
        str: setMuteStr,
      });
    }
  };

  // Colors
  const onColor = "#fff";
  const offColor = "#777";

  // Setting the loop ref
  const loop = (e) => {
    setLoopRef(e);
  };

  // Loop and unloop
  const loopToggle = () => {
    if (audioPlaylist.length >= 1) {
      audioSetup.loopToggler({
        ref: loopRef,
        onColor: onColor,
        offColor: offColor,
      });
    }
  };

  // Volume change
  const changeVolume = (e) => {
    setVolumeVal(e.target.value);
    audioSetup.volumeChanger(e.target.value);
  };

  // Seeking audio position
  const audioSeek = (e) => {
    audioSetup.audioSeeker(e.target.value);
    setSeekVal(e.target.value);
  };

  // Render playlist cards
  const renderPlaylist = () => {
    if (playlist.length > 0) {
      return playlist.map((song, ind) => (
        <SongCard
          key={ind}
          _id={song._id}
          name={song.name}
          artist={song.artist}
          img={song.cover.Location}
          plays={song.plays}
          downloads={song.downloads}
          audioFile={song.audio.Location}
          type={song.type}
          setDisplay={setDisplay}
        />
      ));
    }
  };

  return (
    <>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}

      <Loader loading={loading} />
      <div className="player">
        {/* Pre loader */}

        {/* Song cover and controls */}
        <ArtControls
          PlayAndPause={PlayAndPause}
          goBackward={goBackward}
          goForward={goForward}
          playing={audioPlayed}
          currentTime={currentTime}
          duration={duration}
        />

        {/* Audio seek */}
        <input
          type="range"
          name="seek"
          id="seek"
          style={{
            background: `linear-gradient(90deg,#d41b43ff ${seekVal}%,rgb(255, 255, 255) ${seekVal}%)`,
          }}
          value={seekVal}
          onInput={audioSeek}
        />

        {/* Mini controls */}
        <MiniControls
          volumeVal={volumeVal}
          changeVolume={changeVolume}
          muteStr={muteStr}
          muteUnmute={muteUnmute}
          display={display}
          setDisplay={setDisplay}
          loop={loop}
          loopToggle={loopToggle}
        />

        {/* Song details */}
        {playlist.length > 0 && <SongDetails playing_song={playlist[count]} />}

        <div className="btn">
          <button className="view" onClick={toggleCommentsDisplay}>
            View comments
          </button>
        </div>

        {/* Recommended songs */}
        <div className="recommended">{renderPlaylist()}</div>

        {/* Comments */}
        <Comments
          toggleDisplay={toggleCommentsDisplay}
          displayComments={displayComments}
          setDisplay={setDisplay}
        />
      </div>
    </>
  );
};

export default Player;
