import { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import FLASH, { RESTORE_FLASH } from "../redux/actions/Flash";
import Messages from "../abstracts/Messages";
import Functions from "../abstracts/Functions";

const Register = ({ history }) => {
  // Dispatch
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Katwave | Create A Free Account",
    desc: "Creating a free account",
  });

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Messages
  const [msg, setMsg] = useState({});

  // States
  const [emailVal, setEmailVal] = useState("");
  const [firstNameVal, setFirstNameVal] = useState("");
  const [lastNameVal, setLastNameVal] = useState("");
  const [phoneVal, setPhoneVal] = useState("");
  const [passVal, setPassVal] = useState("");

  // Refs
  const email = createRef();
  const firstName = createRef();
  const lastName = createRef();
  const phone = createRef();
  const password = createRef();
  const confirmPassword = createRef();

  // Regex
  const phoneRegx = /^[0-1][6-8]\d{8}$/g;
  const emailRegx = /@/i;

  // On change events
  const onChange = (e) => {
    const { value, name, style } = e.target;

    if (name === "firstName") {
      setFirstNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "lastName") {
      setLastNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "email") {
      const emailVal = value.toLowerCase();
      setEmailVal(emailVal);
      if (!emailRegx.test(value)) {
        setMsg({ email: "Must include '@'" });
        style.border = "3px solid red";
      } else {
        setMsg({ email: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "phone") {
      setPhoneVal(value);
      if (value.length !== 10) {
        style.border = "3px solid red";
        setMsg({ phone: "Must be 10 digits" });
      } else {
        setMsg({ phone: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "password") {
      setPassVal(value);
      if (value.length < 5) {
        style.border = "3px solid red";
        setMsg({ pass: "Must be 5 char or more" });
      } else {
        setMsg({ pass: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "confirmPassword") {
      if (value.length < 5) {
        style.border = "3px solid red";
        setMsg({ confPass: "Must be 5 char or more" });
      } else if (value !== password.current.value) {
        style.border = "3px solid red";
        setMsg({ confPass: "Passwords don't match" });
      } else {
        setMsg({ confPass: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    }
  };

  const register = (e) => {
    if (firstName.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ first: "Required field!" });
    } else if (lastName.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ last: "Required field!" });
    } else if (email.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ email: "Required field!" });
    } else if (phone.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ phone: "Required field!" });
    } else if (password.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ pass: "Required field!" });
    } else if (confirmPassword.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ confPass: "Required field!" });
    } else if (!emailRegx.test(email.current.value)) {
      e.preventDefault();
      setMsg({ email: "Must include '@'" });
    } else if (!phoneRegx.test(phone.current.value)) {
      e.preventDefault();
      setMsg({ phone: "Invalid phone number" });
    } else if (password.current.value.length < 5) {
      e.preventDefault();
      setMsg({ pass: "Must be 5 char or more" });
    } else if (confirmPassword.current.value.length < 5) {
      e.preventDefault();
      setMsg({ confPass: "Must be 5 char or more" });
    } else if (confirmPassword.current.value !== password.current.value) {
      e.preventDefault();
      setMsg({ confPass: "Passwords don't match" });
    } else {
      e.preventDefault();

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      const firstName = firstNameVal;
      const lastName = lastNameVal;
      const email = emailVal;
      const phone = phoneVal;
      const password = passVal;

      axios({
        url: `${Functions.getAPI()}/register`,
        method: "POST",
        data: {
          firstName,
          lastName,
          email,
          phone,
          password,
        },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));

          // Redirecting
          history.push("/login");
        })
        .catch((err) => {
          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          const res = err.response;
          if (res) {
            const data = res.data;

            // Dispatching
            dispatch(FLASH({ err: data.err, success: "", warn: "" }));
          } else {
            console.log(err);
          }
        });

      setMsg({ email: "", pass: "" });
    }
  };

  return (
    // Register section
    <div className="register-login login">
      <div className="form register-form">
        <div className="account-nav">
          <Link to="/login">Login</Link>
          <Link className="active" to="/register">
            Register
          </Link>
        </div>

        {/* Flash messages */}

        {flash.err !== "" && <Messages err={flash.err} />}
        {flash.success !== "" && <Messages success={flash.success} />}

        {/* Form  */}
        <form className="form-slide" method="POST">
          <input
            type="text"
            className="formInput"
            name="firstName"
            id="first-name"
            placeholder="First name"
            value={firstNameVal}
            ref={firstName}
            onChange={onChange}
          />
          <p style={{ color: "red" }} id="first-name-error">
            {msg.first}
          </p>
          <input
            type="text"
            className="formInput"
            name="lastName"
            id="last-name"
            placeholder="Last name"
            value={lastNameVal}
            ref={lastName}
            onChange={onChange}
          />
          <p style={{ color: "red" }} id="last-name-error">
            {msg.last}
          </p>
          <input
            type="email"
            className="formInput"
            name="email"
            id="email"
            placeholder="E-mail"
            value={emailVal}
            ref={email}
            onChange={onChange}
          />
          <p style={{ color: "red" }} id="email-error">
            {msg.email}
          </p>
          <input
            type="tel"
            className="formInput"
            name="phone"
            id="phone"
            placeholder="Phone"
            value={phoneVal}
            ref={phone}
            onChange={onChange}
          />
          <p style={{ color: "red" }} id="phone-error">
            {msg.phone}
          </p>
          <div className="passwords">
            <input
              type="password"
              className="formInput"
              name="password"
              id="password"
              placeholder="Password"
              value={passVal}
              ref={password}
              onChange={onChange}
            />
          </div>
          <p style={{ color: "red" }} id="password-error">
            {msg.pass}
          </p>
          <div className="passwords">
            <input
              type="password"
              className="formInput"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm password"
              ref={confirmPassword}
              onChange={onChange}
            />
          </div>
          <p style={{ color: "red" }} id="confirmPassword-error">
            {msg.confPass}
          </p>
          <br />
          <button onClick={register} type="submit" className="submit-button">
            Submit
          </button>
        </form>
        <br />
        <Link to="/forgot-password">Forgot Password</Link>
      </div>
    </div>
  );
};

export default Register;
