import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Popup from "../assets/Popup";
import SongCard from "../assets/SongCard";

const SearchedSongs = () => {
  // Use selector
  const flash = useSelector((s) => s.flash);

  // Use state
  const searched_songs = useSelector((s) => s.searched_songs);
  const [display, setDisplay] = useState([]);
  const [q, setQ] = useState("");

  const param = useLocation();

  // Use effect
  useEffect(() => {
    setQ(param.search.replace("?q=", ""));
  }, [q, param.search]);

  // Render song cards
  const renderSongs = () => {
    if (searched_songs.length > 0) {
      return searched_songs.map((song, ind) => (
        <SongCard
          key={ind}
          _id={song._id}
          name={song.name}
          artist={song.artist}
          img={song.cover.Location}
          plays={song.plays}
          downloads={song.downloads}
          audioFile={song.audio.Location}
          type={song.type}
          setDisplay={setDisplay}
        />
      ));
    } else {
      return <h2 className="not-found">No Songs found!</h2>;
    }
  };

  return (
    <div className="main-cont">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}

      <div className="songs-cont">
        <div style={{ marginBottom: "10px" }} className="heading">
          <h1>Results for "{q}"</h1>
        </div>

        {renderSongs()}
      </div>
    </div>
  );
};

export default SearchedSongs;
