import { createRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FLASH, { RESTORE_FLASH } from "../redux/actions/Flash";
import axios from "axios";
import Popup from "../assets/Popup";
import Functions from "../abstracts/Functions";

const Contact = ({ history }) => {
  // Redux state
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Contact Chillode Support",
    desc: "Contact Chillode's Support Team",
  });

  // Redux disparch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(true);
  const [nameVal, setNameVal] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [subjectVal, setSubjectVal] = useState("");
  const [messageVal, SetMessageVal] = useState("");

  // Messages
  const [msg, setMsg] = useState({});

  // Refs
  const name = createRef();
  const email = createRef();
  const subject = createRef();
  const message = createRef();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Email regular expression
  const emailRegx = /\w@\w./i;

  const onChange = (e) => {
    const { value, name, style } = e.target;

    if (name === "name") {
      setNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "email") {
      if (!emailRegx.test(email.current.value)) {
        setEmailVal(value);
        setMsg({ email: "Invalid format (example@domain.com)" });
        style.border = "3px solid red";
      } else {
        setEmailVal(value);
        setMsg({ email: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "subject") {
      setSubjectVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "message") {
      SetMessageVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };

  // Send email
  const send_email = (e) => {
    if (name.current.value.trim() === "") {
      e.preventDefault();
      name.current.style.border = "3px solid red";
      setMsg({ name: "Required field!" });
    } else if (email.current.value.trim() === "") {
      e.preventDefault();
      email.current.style.border = "3px solid red";
      setMsg({ email: "Required field!" });
    } else if (!emailRegx.test(email.current.value)) {
      e.preventDefault();
      email.current.style.border = "3px solid red";
      setMsg({ email: "Must include '@'" });
    } else if (subject.current.value.trim() === "") {
      e.preventDefault();
      subject.current.style.border = "3px solid red";
      setMsg({ subject: "Required field!" });
    } else if (message.current.value.trim() === "") {
      e.preventDefault();
      message.current.style.border = "3px solid red";
      setMsg({ message: "Required field!" });
    } else {
      setDisplay(true);
      e.preventDefault();

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      const name = nameVal;
      const email = emailVal;
      const subject = subjectVal;
      const message = messageVal;

      const data = { email, subject, message, name };

      axios({
        method: "POST",
        url: `${Functions.getAPI()}/contact`,
        data,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // If status is 200, let the user login
          if (res.status === 200) {
            // Dispatching
            dispatch(FLASH({ err: "", success: data.success, warn: "" }));
          }
        })
        .catch((e) => {
          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          const res = e.response;
          if (res) {
            // Dispatching
            dispatch(FLASH({ err: data.err, success: "", warn: "" }));
          } else {
            console.log(e);
          }
        });

      setMsg({});
    }
  };

  return (
    <div className="register-login">
      <div className="form ">
        <div className="heading">
          <h1>Contact support</h1>
        </div>

        {flash.err !== "" && (
          <Popup display={display} setDisplay={setDisplay} err={flash.err} />
        )}
        {flash.success !== "" && (
          <Popup
            display={display}
            setDisplay={setDisplay}
            success={flash.success}
            history={history}
          />
        )}

        <form className="form-slide" method="POST" action="/contact">
          <input
            required
            type="text"
            className="formInput"
            name="name"
            id="name"
            placeholder="Full name"
            value={nameVal}
            onChange={onChange}
            ref={name}
          />
          <p style={{ color: "red" }} id="name-error">
            {msg.name}
          </p>

          <input
            required
            type="email"
            className="formInput"
            name="email"
            id="email"
            placeholder="Email"
            value={emailVal}
            onChange={onChange}
            ref={email}
          />
          <p style={{ color: "red" }} id="email-error">
            {msg.email}
          </p>

          <input
            required
            type="text"
            className="formInput"
            name="subject"
            id="subject"
            placeholder="Subject"
            value={subjectVal}
            onChange={onChange}
            ref={subject}
          />
          <p style={{ color: "red" }} id="subject-error">
            {msg.subject}
          </p>

          <textarea
            required
            name="message"
            id="message"
            placeholder="Type message"
            value={messageVal}
            onChange={onChange}
            ref={message}
          ></textarea>
          <p style={{ color: "red" }} id="message-error">
            {msg.message}
          </p>

          <br />
          <button type="submit" className="submit-button" onClick={send_email}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
