import { Link } from "react-router-dom";
import images from "../../abstracts/Images";

const Hero = () => {
  return (
    <div className="banner">
      <img src={images.hero} alt="Banner" loading="lazy" />
      <div className="opa">
        <div className="welcome-text">
          <h1>I am Katwave</h1>
          <p>Your next big thing starts here!</p>

          <div className="cta">
            <Link to="/book">Book me</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
