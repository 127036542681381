// SHOPS
const SHOPS = (state = {}, { type, payload }) => {
  switch (type) {
    case "SHOPS":
      return payload;
    default:
      return state;
  }
};
// VIEWED_SHOP
const VIEWED_SHOP = (state = {}, { type, payload }) => {
  switch (type) {
    case "VIEWED_SHOP":
      return payload;
    default:
      return state;
  }
};
// SEARCHED_SHOPS
const SEARCHED_SHOPS = (state = {}, { type, payload }) => {
  switch (type) {
    case "SEARCHED_SHOPS":
      return payload;
    default:
      return state;
  }
};
// PRODUCTS
const SHOP_PRODUCTS = (state = [], { type, payload }) => {
  switch (type) {
    case "SHOP_PRODUCTS":
      return payload;
    case "REMOVE_SHOP_PRODUCTS":
      return [];
    default:
      return state;
  }
};
// SEARCHED_PRODUCTS
const SEARCHED_PRODUCTS = (state = [], { type, payload }) => {
  switch (type) {
    case "SEARCHED_PRODUCTS":
      return payload;
    default:
      return state;
  }
};
// PRODUCT PREVIEW
const PRODUCT_PREVIEW = (state = {}, { type, payload }) => {
  switch (type) {
    case "PRODUCT_PREVIEW":
      return { ...state, product: payload };
    case "REMOVE_PREVIEWED_PRODUCT":
      return {};
    default:
      return state;
  }
};
// CART
const CART = (state = { items: [], total: 0 }, { type, payload }) => {
  const getTotal = (items) => {
    let priceOfItems = items.map((item) => parseInt(item.price));
    // Getting the total of all items in the cart
    return priceOfItems.reduce((sum, price) => (sum += price));
  };
  switch (type) {
    case "ADD_TO_CART":
      return { ...state, items: payload.items, total: payload.total };
    case "REMOVE_FROM_CART":
      const index = state.items.findIndex((el) => el._id === payload);
      const items = state.items.filter((el, i) => i !== index);
      const total = items.length >= 1 ? getTotal(items) : 0;
      return { items, total };
    case "UPDATE_CART":
      const leaseType = payload.leaseType;
      const ind = state.items.findIndex((el) => el._id === payload.id);
      state.items[ind].leaseType = leaseType;
      state.items[ind].price = payload.price;
      const updateTotal = getTotal(state.items);
      return { items: state.items, total: updateTotal };
    case "EMPTY_CART":
      return { items: [], total: 0 };
    default:
      return state;
  }
};
// ACCOUNT
const BUYER_ACCOUNT = (
  state = { liked: [], following: [] },
  { type, payload }
) => {
  switch (type) {
    case "FOLLOWING":
      return { ...state, following: payload, liked: state.liked };
    case "UNFOLLOW":
      const updatedShops = state.following.filter((el) => el._id !== payload);
      return { following: updatedShops, liked: state.liked };
    case "LIKED":
      return { ...state, following: state.following, liked: payload };
    case "UNLIKE":
      const updatedLikedProds = state.liked.filter(
        (prod) => prod.id !== payload
      );
      return { liked: updatedLikedProds, following: state.following };
    default:
      return state;
  }
};
// ORDERS
const ORDERS_REDUCER = (state = [], { type, payload }) => {
  switch (type) {
    case "BUYER_ORDERS":
      return payload;
    case "BUYER_ADD_ORDER":
      return [...state, payload];
    case "BUYER_REMOVE_ORDER":
      const updatedOrders = state.filter((el) => el.orderNumber !== payload);
      return updatedOrders;
    case "BUYER_REMOVE_ALL_ORDERS":
      return [];
    default:
      return state;
  }
};

const Buyer = {
  SHOPS,
  SEARCHED_SHOPS,
  VIEWED_SHOP,
  SHOP_PRODUCTS,
  SEARCHED_PRODUCTS,
  PRODUCT_PREVIEW,
  CART,
  BUYER_ACCOUNT,
  ORDERS_REDUCER,
};
export default Buyer;
